import { Outlet } from 'react-router-dom';
import { useEnsureLanguage } from '~/pages/(authenticated)/hooks/useEnsureLanguage';
import { useParams } from '~/router';

export default function Layout() {
  // In this file we know the transactionId, and in turn, we understand the the language
  // of the user.
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId'
  );

  useEnsureLanguage(transactionId, dealershipSlug);

  return (
    <>
      <Outlet />
    </>
  );
}
