import { useAtomValue } from 'jotai';
import { Outlet } from 'react-router-dom';

import { kioskDealershipAtom } from '~/state/kiosk';
import AuthCheckerHook from './_components/AuthCheckerHook';
import LogoutTimer from './_components/LogoutTimer';

export default function Layout() {
  const kioskMode = useAtomValue(kioskDealershipAtom);
  return (
    <>
      {/* Only logged in users are allows here */}
      <AuthCheckerHook />
      <Outlet />
      {kioskMode && <LogoutTimer />}
    </>
  );
}
