import classNames from 'classnames';
import { FC, MouseEvent } from 'react';
import Checkbox from './inputs/Checkbox';

const BinaryQuestion: FC<{
  inputId: string;
  question: string;
  selectedYes: boolean;
  onClickYes: () => void;
  onClickNo: () => void;
  disabled?: boolean;
}> = ({ inputId, question, selectedYes, onClickYes, onClickNo, disabled }) => {
  return (
    <div className="flex flex-col space-y-4">
      <p className="text-tertiary">{question}</p>

      <div className="flex flex-row space-x-4">
        <div className="flex flex-row space-x-2">
          <div
            className="flex items-center"
            onClick={(e: MouseEvent<HTMLDivElement>) => {
              e.preventDefault();
              e.stopPropagation();

              if (!disabled) {
                onClickYes();
              }
            }}
          >
            <Checkbox
              onChange={() => (selectedYes ? onClickNo() : onClickYes())}
              inputId={inputId}
              value={selectedYes}
              disabled={disabled}
              variant="RADIO_BUTTON"
              readOnly
            />
          </div>

          <div className={classNames({ 'text-inactive': disabled })}>Yes</div>
        </div>

        <div className="flex flex-row space-x-2">
          <div
            className="flex items-center"
            onClick={(e: MouseEvent<HTMLDivElement>) => {
              e.preventDefault();
              e.stopPropagation();

              if (!disabled) {
                onClickNo();
              }
            }}
          >
            <Checkbox
              onChange={() => (selectedYes ? onClickNo() : onClickYes())}
              inputId={inputId}
              value={!selectedYes}
              disabled={disabled}
              variant="RADIO_BUTTON"
              readOnly
            />
          </div>

          <div className={classNames({ 'text-inactive': disabled })}>No</div>
        </div>
      </div>
    </div>
  );
};

export default BinaryQuestion;
