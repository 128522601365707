import classNames from 'classnames';
import ExclamationTriangleIcon from './icons/ExclamationTriangleIcon';
import LockIcon from './icons/LockIcon';

type Props = {
  children: React.ReactNode;
  variant: 'ERROR' | 'PRIVACY';
};

/**
 *
 * @deprecated Use Banner instead
 * @param param0
 * @returns
 */
export default function StandOut({ children, variant }: Props) {
  return (
    <div
      className={classNames(' p-2 flex space-x-4 align-middle', {
        'bg-negative-secondary text-negative': variant === 'ERROR',
        'bg-secondary': variant === 'PRIVACY'
      })}
    >
      <div>
        {variant === 'PRIVACY' && <LockIcon className="icon-interactive w-6" />}
        {variant === 'ERROR' && (
          <ExclamationTriangleIcon className="icon-negative w-6" />
        )}
      </div>
      {children}
    </div>
  );
}
