import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import logoutAction from '~/actions/logoutAction';
import Button from '~/components/Button';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DocumentIcon from '~/components/icons/DocumentIcon';
import Checkbox from '~/components/inputs/Checkbox';
import { Link, useNavigate, useParams } from '~/router';
import ConsumerDashboardHeader from '../../../_components/ConsumerDashboardHeader';

const ModificationNoticePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { transactionId, hardCreditApplicationId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/modification/modificationNotice'
  );
  const [accepted, setAccepted] = useState(false);
  const [loggingOut, setLoggingOut] = useState(false);
  const handleLogout = async () => {
    setLoggingOut(true);
    await logoutAction();
  };
  const handleContinue = () => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/modification/modificationForm',
      {
        params: {
          dealershipSlug,
          transactionId,
          hardCreditApplicationId
        }
      }
    );
  };

  return (
    <div className="flex flex-col h-dvh">
      <ConsumerDashboardHeader
        leftElement={
          <div className="relative">
            <img src="/logo.png" className="h-4" aria-label="Logo" />
          </div>
        }
        centerElement={
          <div className="flex flex-row space-x-2">
            <div className="relative">
              <DocumentIcon className="size-4 md:size-5 icon-primary" />
            </div>

            <h2 className="hidden md:flex items-center">Documents & Forms</h2>

            <h3 className="flex md:hidden items-center">Documents & Forms</h3>
          </div>
        }
        rightElement={
          <Button
            variant="TEXT_ONLY"
            size="SMALL"
            onClick={() => void handleLogout()}
            loading={loggingOut}
            className="!p-0"
          >
            <span className="text-secondary">{t('Logout')}</span>
          </Button>
        }
        showBorder
      />

      <div className="flex w-full max-w-screen-md z-30 self-center pt-4 px-6 lg:px-0">
        <Link
          to="/dashboard/:dealershipSlug/:transactionId"
          params={{ transactionId, dealershipSlug }}
          className="flex w-6"
        >
          <div className="relative">
            <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
          </div>
        </Link>
      </div>

      <div className="flex flex-col self-center w-full max-w-screen-md space-y-6 mt-6 md:mt-14 px-6 lg:px-0">
        <h1 className="text-title">{t('Credit Application Modification')}</h1>

        <p>
          {t(
            'This feature gives you an option to submit a New Application Form. The fields will be prefilled with any previously entered information for you to edit/modify.'
          )}
        </p>

        <div
          className="flex flex-row space-x-6 items-start cursor-pointer"
          onClick={() => {
            setAccepted((a) => !a);
          }}
        >
          <Checkbox
            inputId="acknowledged"
            value={accepted}
            onChange={() => {
              setAccepted((a) => !a);
            }}
            containerClassName="mt-1"
          />

          <p>
            {t(
              'I am modifying my original application due to an error or omission on my behalf. I do this of my own free will and understand that this information may be used to make a credit decision by a bank or other similar entity.'
            )}
          </p>
        </div>

        <div className="flex flex-row justify-end w-full max-w-screen-md">
          <Button onClick={handleContinue} disabled={!accepted}>
            {t('Next')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ModificationNoticePage;
