import { OnboardingStep } from '~/__generated__/backend/zeus';
import { useNavigate } from '~/router';

export const navigateToTransaction = (
  transactionId: string,
  dealershipSlug: string,
  navigate: ReturnType<typeof useNavigate>,
  step?: OnboardingStep
) => {
  switch (step) {
    case 'STEP1':
      navigate('/onboarding/:dealershipSlug/:transactionId/step1', {
        params: { transactionId, dealershipSlug }
      });
      break;
    case 'STEP2':
      navigate('/onboarding/:dealershipSlug/:transactionId/step2', {
        params: { transactionId, dealershipSlug }
      });
      break;
    default:
      // Typically when it is undefined
      navigate('/dashboard/:dealershipSlug/:transactionId', {
        params: { transactionId, dealershipSlug }
      });
  }
};
