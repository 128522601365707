import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@thedealersconcierge/components';
import { parsePhoneNumber } from 'libphonenumber-js';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import createAddressAction from '~/actions/addresses/createAddressAction';
import updateAddressAction from '~/actions/addresses/updateAddressAction';
import createEmploymentAction from '~/actions/employments/createEmploymentAction';
import deleteEmploymentAction from '~/actions/employments/deleteEmploymentAction';
import updateEmploymentAction from '~/actions/employments/updateEmploymentAction';
import Header from '~/components/Header';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DropDown from '~/components/inputs/DropDown';
import NumberInput, {
  parseNumberInputValue
} from '~/components/inputs/NumberInput';
import PhoneNumberInput from '~/components/inputs/PhoneNumberInput';
import TextInput from '~/components/inputs/TextInput';
import Spinner from '~/components/Spinner';
import employmentStatusOptions from '~/config/formSelectionOptions/employmentStatusOptions';
import incomeIntervalOptions from '~/config/formSelectionOptions/incomeIntervalOptions';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import customerQuery, { resetCustomerQuery } from '~/queries/customerQuery';
import { resetHardCreditQuery } from '~/queries/hardCreditQuery';
import meQuery, { resetMeQuery } from '~/queries/meQuery';
import { EmploymentType } from '~/querySelectors/employment';
import { Link, useNavigate, useParams } from '~/router';

const HardCreditApplicationEmploymentInfoForm: FC<{
  userId: string;
  employments?: EmploymentType[];
  isDeleting: boolean;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  userId,
  employments,
  isDeleting,
  setIsDeleting,
  isSubmitting,
  setIsSubmitting
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { transactionId, hardCreditApplicationId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/employmentInfo'
  );
  const [currentlyEditedEmployment, setCurrentlyEditedEmployment] =
    useState<EmploymentType | null>(null);
  const moreThanTwoYears =
    (employments
      ?.map((e) => (e.durationYears ?? 0) + (e.durationMonths ?? 0) / 12)
      .reduce((total, duration) => duration + total, 0) ?? 0) >= 2;
  const handleContinue = () => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/personalReference',
      {
        params: {
          dealershipSlug,
          transactionId,
          hardCreditApplicationId
        }
      }
    );
  };
  const handleDelete = async (employmentId: string) => {
    setIsDeleting(true);

    await deleteEmploymentAction(employmentId);
    await Promise.all([
      resetMeQuery(),
      resetCustomerQuery(transactionId, userId),
      resetHardCreditQuery(transactionId)
    ]);

    setCurrentlyEditedEmployment(null);
    form.reset();
    setIsDeleting(false);
  };
  const form = useForm({
    defaultValues: {
      employmentStatus: currentlyEditedEmployment?.employmentStatus ?? '',
      employerName: currentlyEditedEmployment?.employerName ?? '',
      occupation: currentlyEditedEmployment?.occupation ?? '',
      employerPhoneNumber: currentlyEditedEmployment?.employerPhoneNumber ?? '',
      employerStreet: currentlyEditedEmployment?.employerAddress?.street ?? '',
      employerApartmentDetails:
        currentlyEditedEmployment?.employerAddress?.apartmentDetails ?? '',
      employerZipCode:
        currentlyEditedEmployment?.employerAddress?.zipCode ?? '',
      employerCity: currentlyEditedEmployment?.employerAddress?.city ?? '',
      employerState: currentlyEditedEmployment?.employerAddress?.state ?? '',
      durationYears: currentlyEditedEmployment?.durationYears?.toString() ?? '',
      durationMonths:
        currentlyEditedEmployment?.durationMonths?.toString() ?? '',
      incomeAmount: currentlyEditedEmployment?.incomeAmount?.toString() ?? '',
      incomeInterval: currentlyEditedEmployment?.incomeInterval ?? '',
      otherIncomeSource: currentlyEditedEmployment?.otherIncomeSource ?? '',
      otherIncomeAmount:
        currentlyEditedEmployment?.otherIncomeAmount?.toString() ?? '',
      otherIncomeInterval: currentlyEditedEmployment?.otherIncomeInterval ?? ''
    },
    onSubmit: async (values, form) => {
      setIsSubmitting(true);

      if (currentlyEditedEmployment) {
        let employerAddressId: string | undefined = undefined;

        // Update or create the employer address if necessary
        if (['EMPLOYED', 'SELF_EMPLOYED'].includes(values.employmentStatus)) {
          if (currentlyEditedEmployment.employerAddress?.id) {
            employerAddressId = currentlyEditedEmployment.employerAddress.id;

            await updateAddressAction(employerAddressId, {
              street: values.employerStreet,
              apartmentDetails: values.employerApartmentDetails,
              zipCode: values.employerZipCode,
              state: values.employerState,
              city: values.employerCity
            });
          } else {
            const createdAddress = await createAddressAction({
              street: values.employerStreet,
              apartmentDetails: values.employerApartmentDetails,
              zipCode: values.employerZipCode,
              state: values.employerState,
              city: values.employerCity
            });

            employerAddressId = createdAddress?.id;
          }
        }

        await updateEmploymentAction(
          currentlyEditedEmployment.id ?? 'no-employment-id',
          {
            employmentStatus: values.employmentStatus,
            employerName: values.employerName,
            occupation: values.occupation,
            employerPhoneNumber: values.employerPhoneNumber.length // We have to check whether the phone number has been set because an empty string will fail the phone number validation
              ? values.employerPhoneNumber
              : undefined,
            employerAddressId,
            durationYears: parseNumberInputValue(values.durationYears),
            durationMonths: values.durationMonths
              ? (parseNumberInputValue(values.durationMonths) ?? 0)
              : 0,
            incomeAmount: parseNumberInputValue(values.incomeAmount),
            incomeInterval: values.incomeInterval,
            otherIncomeSource: values.otherIncomeSource,
            otherIncomeAmount: parseNumberInputValue(values.otherIncomeAmount),
            otherIncomeInterval: values.otherIncomeInterval.length
              ? values.otherIncomeInterval
              : undefined // otherIncomeInterval is optional, so we have to pass undefined if the value is an empty string,
          }
        );
      } else {
        let employerAddressId: string | undefined = undefined;

        // Create the employer address if necessary
        if (['EMPLOYED', 'SELF_EMPLOYED'].includes(values.employmentStatus)) {
          const createdAddress = await createAddressAction({
            street: values.employerStreet,
            apartmentDetails: values.employerApartmentDetails,
            zipCode: values.employerZipCode,
            state: values.employerState,
            city: values.employerCity
          });

          employerAddressId = createdAddress?.id;
        }

        await createEmploymentAction(transactionId, {
          employmentStatus: values.employmentStatus,
          employerName: values.employerName,
          occupation: values.occupation,
          employerPhoneNumber: values.employerPhoneNumber.length // We have to check whether the phone number has been set becausean empty string will fail the phone number validation
            ? values.employerPhoneNumber
            : undefined,
          employerAddressId,
          durationYears: parseNumberInputValue(values.durationYears) ?? 0,
          durationMonths: values.durationMonths
            ? (parseNumberInputValue(values.durationMonths) ?? 0)
            : 0,
          incomeAmount: parseNumberInputValue(values.incomeAmount) ?? 0,
          incomeInterval: values['incomeInterval'],
          otherIncomeSource: values['otherIncomeSource'],
          otherIncomeAmount: parseNumberInputValue(values.otherIncomeAmount),
          otherIncomeInterval: values['otherIncomeInterval'].length
            ? values['otherIncomeInterval']
            : undefined, // otherIncomeInterval is optional, so we have to pass undefined if the value is an empty string
          timelinePosition: employments?.length ?? 0
        });
      }

      await Promise.all([
        resetMeQuery(),
        resetCustomerQuery(transactionId, userId),
        resetHardCreditQuery(transactionId)
      ]);

      setCurrentlyEditedEmployment(null);
      form.reset();
      setIsSubmitting(false);
    }
  });
  const handleEdit = (employment: EmploymentType) => {
    form.setFieldValue('employmentStatus', employment.employmentStatus ?? '');
    form.setFieldValue('employerName', employment.employerName ?? '');
    form.setFieldValue('occupation', employment.occupation ?? '');
    form.setFieldValue(
      'employerPhoneNumber',
      employment.employerPhoneNumber ?? ''
    );
    form.setFieldValue(
      'employerStreet',
      employment.employerAddress?.street ?? ''
    );
    form.setFieldValue(
      'employerApartmentDetails',
      employment.employerAddress?.apartmentDetails ?? ''
    );
    form.setFieldValue(
      'employerZipCode',
      employment.employerAddress?.zipCode ?? ''
    );
    form.setFieldValue('employerCity', employment.employerAddress?.city ?? '');
    form.setFieldValue(
      'employerState',
      employment.employerAddress?.state ?? ''
    );
    form.setFieldValue(
      'durationYears',
      employment.durationYears?.toString() ?? ''
    );
    form.setFieldValue(
      'durationMonths',
      employment.durationMonths?.toString() ?? ''
    );
    form.setFieldValue(
      'incomeAmount',
      employment.incomeAmount?.toString() ?? ''
    );
    form.setFieldValue('incomeInterval', employment.incomeInterval ?? '');
    form.setFieldValue('otherIncomeSource', employment.otherIncomeSource ?? '');
    form.setFieldValue(
      'otherIncomeAmount',
      employment.otherIncomeAmount?.toString() ?? ''
    );
    form.setFieldValue(
      'otherIncomeInterval',
      employment.otherIncomeInterval ?? ''
    );

    setCurrentlyEditedEmployment(employment);
  };
  const renderEmployment = (employment: EmploymentType) => {
    return (
      <div
        key={employment.id}
        className="flex flex-col space-y-4 w-full rounded-lg overflow-hidden shadow-border p-4"
      >
        <div className="flex flex-col-reverse md:flex-row md:justify-between gap-2">
          <h2>
            {(employment.timelinePosition ?? 0) === 0 &&
              t('Current Employment')}
            {(employment.timelinePosition ?? 0) > 0 &&
              t('previousEmployment', {
                count: employment.timelinePosition ?? 0,
                ordinal: true,
                context: 'male'
              })}
          </h2>

          <div className="flex flex-row items-center justify-end">
            <Button
              label={t('Edit')}
              size="SMALL"
              variant="GHOST"
              onClick={() => {
                handleEdit(employment);
              }}
              disabled={isSubmitting || isDeleting}
            />

            <span className="icon-tertiary text-sm">|</span>

            <Button
              label={t('Delete')}
              size="SMALL"
              variant="GHOST"
              disabled={(employments?.length ?? 0) < 2 || isSubmitting}
              onClick={() => {
                void handleDelete(employment.id ?? 'no-employment-id');
              }}
              isLoading={isDeleting}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          {employment.employerName && (
            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">{t('Employer Name:')}</p>

              <p className="text-secondary">{employment.employerName}</p>
            </div>
          )}

          {(employment.occupation || employment.employerPhoneNumber) && (
            <Fragment>
              <div className="space-y-2">
                <p className="text-body-2 text-tertiary">{t('Occupation:')}</p>

                <p className="text-secondary">{employment.occupation}</p>
              </div>

              <div className="space-y-2">
                <p className="text-body-2 text-tertiary">
                  {t('Employer Phone Number:')}
                </p>

                <p className="text-secondary">
                  {employment.employerPhoneNumber
                    ? parsePhoneNumber(employment.employerPhoneNumber).format(
                        parsePhoneNumber(employment.employerPhoneNumber)
                          .country === 'US'
                          ? 'NATIONAL'
                          : 'INTERNATIONAL'
                      )
                    : ''}
                </p>
              </div>
            </Fragment>
          )}

          {employment.employerAddress && (
            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">
                {t('Employer Address:')}
              </p>

              <p className="text-secondary">
                {employment.employerAddress.fullAddress}
              </p>
            </div>
          )}

          <div className="space-y-2">
            <p className="text-body-2 text-tertiary">
              {t('Employment Status:')}
            </p>

            <p className="text-secondary">
              {
                employmentStatusOptions(t).find(
                  (o) => o.value === employment.employmentStatus
                )?.label
              }
            </p>
          </div>

          <div className="space-y-2">
            <p className="text-body-2 text-tertiary">
              {t('Employment Duration:')}
            </p>

            <p className="text-secondary">
              {employment.durationYears} Yrs {employment.durationMonths} Mos
            </p>
          </div>

          <div className="space-y-2">
            <p className="text-body-2 text-tertiary">{t('Salary:')}</p>

            <p className="text-secondary">
              ${employment.incomeAmount?.toLocaleString()}
            </p>
          </div>

          <div className="space-y-2">
            <p className="text-body-2 text-tertiary">{t('Per Time Period:')}</p>

            <p className="text-secondary">
              {
                incomeIntervalOptions(t).find(
                  (o) => o.value === employment.incomeInterval
                )?.label
              }
            </p>
          </div>

          {employment.otherIncomeSource && (
            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">
                {t('Other Income Source:')}
              </p>

              <p className="text-secondary">{employment.otherIncomeSource}</p>
            </div>
          )}

          {employment.otherIncomeAmount && (
            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">{t('Income Amount:')}</p>

              <p className="text-secondary">
                ${employment.otherIncomeAmount.toLocaleString()}
              </p>
            </div>
          )}

          {employment.otherIncomeInterval && (
            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">
                {t('Per Time Period:')}
              </p>

              <p className="text-secondary">
                {
                  incomeIntervalOptions(t).find(
                    (o) => o.value === employment.otherIncomeInterval
                  )?.label
                }
              </p>
            </div>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col space-y-8 md:space-y-16 w-full items-center">
      {(!moreThanTwoYears || currentlyEditedEmployment) && (
        <div className="flex flex-col w-full space-y-4 bg-secondary border-y border-secondary items-center py-6 px-6">
          <div className="flex flex-row justify-between w-full max-w-screen-md items-center">
            {/**
             * When no employment exists yet, we are basically adding the current employment
             */}
            <h2>
              {(currentlyEditedEmployment?.timelinePosition ??
                employments?.length ??
                0) === 0 && t('Current Employment Information')}
              {(currentlyEditedEmployment?.timelinePosition ??
                employments?.length ??
                0) > 0 &&
                t('previousEmploymentInformation', {
                  ordinal: true,
                  count:
                    currentlyEditedEmployment?.timelinePosition ??
                    employments?.length ??
                    0
                })}
            </h2>
          </div>

          <form.Provider>
            <form
              className="grid grid-cols-2 md:grid-cols-8 gap-4 w-full max-w-screen-md items-end"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                void form.handleSubmit();
              }}
            >
              <form.Field name="employmentStatus">
                {(field) => {
                  return (
                    <DropDown
                      fieldName={field.name}
                      value={field.state.value}
                      options={employmentStatusOptions(t)}
                      labelText={t('Employment Status')}
                      subtitleText={t('Employment Status')}
                      placeholder={t('Employment Status')}
                      required
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                      containerClassName="col-span-2 md:col-span-8"
                      dataTestId="hca-step3-employmentStatus-input"
                    />
                  );
                }}
              </form.Field>

              <form.Subscribe
                selector={(state) => [state.values]}
                children={([values]) => {
                  return (
                    <Fragment>
                      {['EMPLOYED', 'SELF_EMPLOYED'].includes(
                        values.employmentStatus
                      ) && (
                        <Fragment>
                          <form.Field name="employerName">
                            {(field) => {
                              return (
                                <TextInput
                                  fieldName={field.name}
                                  value={field.state.value}
                                  labelText={t('Employer Name')}
                                  subtitleText={t('Employer Name')}
                                  placeholder={t('Employer Name')}
                                  required
                                  disabled={isSubmitting || isDeleting}
                                  error={field.state.meta.touchedErrors.at(0)}
                                  onChange={(e) => {
                                    field.handleChange(e.target.value);
                                  }}
                                  containerClassName="col-span-2 md:col-span-8"
                                  dataTestId="hca-step3-employerName-input"
                                />
                              );
                            }}
                          </form.Field>

                          <form.Field name="employerStreet">
                            {(field) => {
                              return (
                                <TextInput
                                  fieldName={field.name}
                                  value={field.state.value}
                                  labelText={t('Employer Address')}
                                  subtitleText={t('Employer Address')}
                                  placeholder={t('Employer Address')}
                                  required
                                  disabled={isSubmitting || isDeleting}
                                  error={field.state.meta.touchedErrors.at(0)}
                                  onChange={(e) => {
                                    field.handleChange(e.target.value);
                                  }}
                                  containerClassName="col-span-2 md:col-span-4"
                                  dataTestId="hca-step3-employerStreet-input"
                                />
                              );
                            }}
                          </form.Field>

                          <form.Field name="employerApartmentDetails">
                            {(field) => {
                              return (
                                <TextInput
                                  fieldName={field.name}
                                  value={field.state.value}
                                  labelText={t('Apartment Details')}
                                  subtitleText={t('Apartment Details')}
                                  placeholder={t('Suite, Apt #')}
                                  disabled={isSubmitting || isDeleting}
                                  error={field.state.meta.touchedErrors.at(0)}
                                  onChange={(e) => {
                                    field.handleChange(e.target.value);
                                  }}
                                  containerClassName="md:col-span-4"
                                  dataTestId="hca-step3-employerApartmentDetails-input"
                                />
                              );
                            }}
                          </form.Field>

                          <form.Field name="employerZipCode">
                            {(field) => {
                              return (
                                <TextInput
                                  fieldName={field.name}
                                  value={field.state.value}
                                  labelText={t('Zip Code')}
                                  subtitleText={t('Zip Code')}
                                  placeholder={t('Zip Code')}
                                  required
                                  disabled={isSubmitting || isDeleting}
                                  error={field.state.meta.touchedErrors.at(0)}
                                  onChange={(e) => {
                                    field.handleChange(e.target.value);
                                  }}
                                  containerClassName="md:col-span-3"
                                  dataTestId="hca-step3-employerZipCode-input"
                                />
                              );
                            }}
                          </form.Field>

                          <form.Field name="employerCity">
                            {(field) => {
                              return (
                                <TextInput
                                  fieldName={field.name}
                                  value={field.state.value}
                                  labelText={t('City')}
                                  subtitleText={t('City')}
                                  placeholder={t('City')}
                                  required
                                  disabled={isSubmitting || isDeleting}
                                  error={field.state.meta.touchedErrors.at(0)}
                                  onChange={(e) => {
                                    field.handleChange(e.target.value);
                                  }}
                                  containerClassName="md:col-span-2"
                                  dataTestId="hca-step3-employerCity-input"
                                />
                              );
                            }}
                          </form.Field>

                          <form.Field name="employerState">
                            {(field) => {
                              return (
                                <DropDown
                                  fieldName={field.name}
                                  value={field.state.value}
                                  options={stateOptions}
                                  labelText={t('State')}
                                  placeholder={t('State')}
                                  subtitleText={t('State')}
                                  required
                                  disabled={isSubmitting || isDeleting}
                                  error={field.state.meta.touchedErrors.at(0)}
                                  onChange={(e) => {
                                    field.handleChange(e.target.value);
                                  }}
                                  containerClassName="md:col-span-3"
                                  dataTestId="hca-step3-employerState-input"
                                />
                              );
                            }}
                          </form.Field>

                          {/** The naming of the field changed in the designs. So label/subtitle texts are different than the variable name */}
                          <form.Field name="occupation">
                            {(field) => {
                              return (
                                <TextInput
                                  fieldName={field.name}
                                  value={field.state.value}
                                  labelText={t('Role')}
                                  subtitleText={t('Role')}
                                  placeholder={t('Role')}
                                  required
                                  disabled={isSubmitting || isDeleting}
                                  error={field.state.meta.touchedErrors.at(0)}
                                  onChange={(e) => {
                                    field.handleChange(e.target.value);
                                  }}
                                  containerClassName="col-span-2 md:col-span-8"
                                  dataTestId="hca-step3-occupation-input"
                                />
                              );
                            }}
                          </form.Field>

                          <form.Field name="employerPhoneNumber">
                            {(field) => {
                              return (
                                <PhoneNumberInput
                                  fieldName={field.name}
                                  value={field.state.value}
                                  subtitleText={t('Employer Phone Number')}
                                  placeholder={t('Employer Phone Number')}
                                  onChange={(value) => {
                                    field.handleChange(value?.toString() ?? '');
                                  }}
                                  onBlur={field.handleBlur}
                                  disabled={isSubmitting || isDeleting}
                                  required
                                  containerClassName="col-span-2 md:col-span-8"
                                  dataTestId="hca-step3-employerPhoneNumber-input"
                                />
                              );
                            }}
                          </form.Field>
                        </Fragment>
                      )}

                      <form.Field name="durationYears">
                        {(field) => {
                          return (
                            <NumberInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('Duration in Years')}
                              subtitleText={t(
                                'How many years have you been working at this job?'
                              )}
                              placeholder={t('Duration (Yrs.)')}
                              required
                              suffix={t(' Yrs.')}
                              disabled={isSubmitting || isDeleting}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-4"
                              dataTestId="hca-step3-durationYears-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="durationMonths">
                        {(field) => {
                          return (
                            <NumberInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('Duration in Months')}
                              subtitleText={t(
                                'How many months have you been working at this job?'
                              )}
                              placeholder={t('Duration (Mos.)')}
                              suffix={t(' Mos.')}
                              disabled={isSubmitting || isDeleting}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-4 h-full"
                              dataTestId="hca-step3-durationMonths-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="incomeAmount">
                        {(field) => {
                          return (
                            <NumberInput
                              fieldName={field.name}
                              value={field.state.value}
                              subtitleText={t('Gross Income')}
                              placeholder={t('Gross Income')}
                              disabled={isSubmitting || isDeleting}
                              prefix="$"
                              required
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-4"
                              dataTestId="hca-step3-incomeAmount-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="incomeInterval">
                        {(field) => {
                          return (
                            <DropDown
                              fieldName={field.name}
                              value={field.state.value}
                              options={incomeIntervalOptions(t)}
                              labelText={t('Per Time Period')}
                              subtitleText={t('Per Time Period')}
                              placeholder={t('Per Time Period')}
                              required
                              disabled={isSubmitting || isDeleting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-4"
                              dataTestId="hca-step3-incomeInterval-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="otherIncomeSource">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('Other Income Source')}
                              subtitleText={t('Other Income Source')}
                              placeholder={t('Other Income Source')}
                              disabled={isSubmitting || isDeleting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-8"
                              dataTestId="hca-step3-otherIncomeSource-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="otherIncomeAmount">
                        {(field) => {
                          return (
                            <NumberInput
                              fieldName={field.name}
                              value={field.state.value}
                              subtitleText={t('Gross Income')}
                              placeholder={t('Gross Income')}
                              disabled={isSubmitting || isDeleting}
                              prefix="$"
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-4"
                              dataTestId="hca-step3-otherIncomeAmount-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="otherIncomeInterval">
                        {(field) => {
                          return (
                            <DropDown
                              fieldName={field.name}
                              value={field.state.value}
                              options={incomeIntervalOptions(t)}
                              labelText={t('Per Time Period')}
                              subtitleText={t('Per Time Period')}
                              placeholder={t('Per Time Period')}
                              disabled={isSubmitting || isDeleting}
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="col-span-2 md:col-span-4"
                              dataTestId="hca-step3-otherIncomeInterval-input"
                            />
                          );
                        }}
                      </form.Field>
                    </Fragment>
                  );
                }}
              />

              <div className="flex flex-row col-span-2 md:col-span-8 justify-end">
                <form.Subscribe
                  selector={(state) => [state.values]}
                  children={([values]) => {
                    let canContinue =
                      !!values.employmentStatus &&
                      !!values.incomeAmount &&
                      !!values.incomeInterval &&
                      values.durationYears !== '' &&
                      (parseInt(values.durationYears.toString()) > 0 ||
                        parseInt(values.durationMonths.toString()) > 0);

                    if (
                      canContinue &&
                      ['EMPLOYED', 'SELF_EMPLOYED'].includes(
                        values.employmentStatus
                      )
                    ) {
                      canContinue =
                        !!values.employerName &&
                        !!values.occupation &&
                        !!values.employerPhoneNumber &&
                        !!values.employerStreet &&
                        !!values.employerZipCode &&
                        !!values.employerCity &&
                        !!values.employerState;
                    }

                    return (
                      <Button
                        label={t('Save')}
                        disabled={!canContinue || isDeleting}
                        isLoading={isSubmitting}
                        type="submit"
                        dataTestId="hca-step3-save-button"
                      />
                    );
                  }}
                />
              </div>
            </form>
          </form.Provider>
        </div>
      )}

      {!!employments?.length && (
        <div className="flex w-full px-6 justify-center">
          <div className="flex flex-col space-y-12 w-full max-w-screen-md bg-primary">
            {employments.map((e) => {
              return renderEmployment(e);
            })}
          </div>
        </div>
      )}

      <div className="flex w-full px-6 justify-center">
        <div className="flex flex-row justify-end w-full max-w-screen-md">
          <Button
            label={t('Next')}
            onClick={handleContinue}
            disabled={isSubmitting || !moreThanTwoYears || isDeleting}
            dataTestId="hca-step3-next-button"
          />
        </div>
      </div>
    </div>
  );
};

const HardCreditApplicationEmploymentInfoPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { transactionId, hardCreditApplicationId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/employmentInfo'
  );
  const { data: meData } = useQuery(meQuery());
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const userId = meData?.me?.user?.id ?? 'no-user-id';
  const { data: customer, isFetched } = useQuery(
    customerQuery(transactionId, userId, dealershipSlug)
  );

  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };

  const employments = customer?.customer?.employments;

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title="Hard Credit Application"
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting || isDeleting}
          >
            {t('Cancel')}
          </button>
        }
        totalSteps={5}
        currentStep={3}
      />

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center pt-4">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/addressInfo"
            params={{ transactionId, hardCreditApplicationId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      <div
        className="flex flex-col py-10 space-y-6 w-full items-center overflow-y-scroll h-full"
        data-test-id={'hca-step-3-body'}
      >
        {isFetched ? (
          <Fragment>
            <div className="flex w-full px-6 justify-center">
              <div className="flex flex-col space-y-4 w-full max-w-screen-md bg-primary">
                <h2>{t('Credit Application')}</h2>

                <p>
                  {t(
                    'Including the last two years of your employment is required.'
                  )}
                </p>
              </div>
            </div>

            <HardCreditApplicationEmploymentInfoForm
              userId={userId}
              employments={employments?.edges?.map((e) => e.node ?? {})}
              isDeleting={isDeleting}
              setIsDeleting={setIsDeleting}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
            />
          </Fragment>
        ) : (
          <div className="flex w-full aspect-square justify-center items-center relative">
            <Spinner className="w-10 aspect-square" />
          </div>
        )}
      </div>
    </div>
  );
};

export default HardCreditApplicationEmploymentInfoPage;
