import classNames from 'classnames';
import { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { maskSSNRegex } from '~/lib/stringUtils';

const SocialSecurityNumberInput: FC<{
  value: string;
  fieldName: string;
  required?: boolean;
  error?: string;
  subtitleText?: string;
  placeholder?: string;
  disabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: () => void;
  setFieldValue: (fieldName: string, value: string) => void;
  containerClassName?: string;
  dataTestId?: string;
}> = ({
  value,
  fieldName,
  required,
  error,
  subtitleText,
  placeholder,
  disabled,
  onChange,
  onBlur,
  setFieldValue,
  containerClassName,
  dataTestId
}) => {
  const { t } = useTranslation();
  const [isFocused, setIsFocused] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const formatSSN = (value: string) => {
    // Remove all non-digit characters for a clean slate
    const digits = value.replace(/\D/g, '');

    // Break the string into parts and join with hyphens
    const parts = [];

    parts.push(digits.substring(0, 3)); // First three digits

    if (digits.length > 3) {
      parts.push(digits.substring(3, 5)); // Next two digits
    }

    if (digits.length > 5) {
      parts.push(digits.substring(5)); // Remaining digits
    }

    const result = parts.join('-').substring(0, 11); // Remove excess characters

    setFieldValue(fieldName, result);

    // TODO: Raise validation errors

    return result;
  };

  const maskSSN = (ssn?: string) => {
    if (isFocused || !ssn) {
      return ssn;
    }

    return maskSSNRegex(ssn);
  };

  useEffect(() => {
    if (inputRef.current) {
      const input = inputRef.current;
      const handleInputEvent = (event: HTMLElementEventMap['input']) => {
        const target = event.target as HTMLInputElement;
        target.value = formatSSN(target.value);
      };

      input.addEventListener('input', handleInputEvent);

      return () => {
        input.removeEventListener('input', handleInputEvent);
      };
    }
  }, [inputRef]);

  return (
    <div className={classNames('flex flex-col space-y-2', containerClassName)}>
      <label htmlFor={fieldName} className="hidden">
        Social Security Number
      </label>

      <input
        data-test-id={dataTestId}
        ref={inputRef}
        type="text"
        placeholder={placeholder ?? 'Social Security Number'}
        name={fieldName}
        onChange={onChange}
        value={isFocused ? value : maskSSN(value)}
        onBlur={() => {
          setIsFocused(false);
          onBlur();
        }}
        onFocus={() => {
          setIsFocused(true);
        }}
        disabled={disabled}
        className="border-b-[0.5px] py-2 px-3 space-x-2 appearance-none outline-none h-10 bg-transparent disabled:disabled-input placeholder:text-inactive"
      />

      <div className="flex flex-row">
        {subtitleText && !error && (
          <p
            className={classNames('text-body-3 ml-2 text-tertiary', {
              'text-inactive': disabled // We can use the "disabled:" TailwindCSS only on inputs
            })}
          >
            {subtitleText}
          </p>
        )}

        {required && !error && (
          <p
            className={classNames('text-body-3 ml-2 text-tertiary italic', {
              'text-inactive': disabled // We can use the "disabled:" TailwindCSS only on inputs
            })}
          >
            {t('Required')}
          </p>
        )}

        {error && <p className="text-body-3 ml-2 text-negative">{error}</p>}
      </div>
    </div>
  );
};

export default SocialSecurityNumberInput;
