import { DocumentTemplate } from "@thedealersconcierge/lib/pdf-gen";

// This can probably be inferred
type Fields =
  | "dealershipName"
  | "dealershipWebsite"
  | "date"
  | "name"
  | "signature";
export const privacyDocumentTemplate = (
  path: string,
  isUrl: boolean
): DocumentTemplate<Fields> => ({
  fileUrl: isUrl ? path : undefined,
  filePath: !isUrl ? path : undefined,
  fields: {
    dealershipName: {
      formFieldId: "form-privacy-notice-field-1",
      formId: "form-privacy-notice",
      demoData: "John Doe",
      formFieldPlacements: [
        {
          formFieldPlacementId: "form-privacy-notice-field-1-placement-1",
          positionX: 230,
          positionY: 46,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false,
        },
      ],
      type: "TEXT",
      createdAt: "2024-05-21 00:00:00.000",
    },
    dealershipWebsite: {
      formFieldId: "form-privacy-notice-field-2",
      formId: "form-privacy-notice",
      demoData: "https://www.dealership.com",
      formFieldPlacements: [
        {
          formFieldPlacementId: "form-privacy-notice-field-2-placement-1",
          positionX: 225,
          positionY: 722,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false,
        },
      ],
      type: "TEXT",
      createdAt: "2024-05-21 00:00:00.000",
    },
    date: {
      formFieldId: "form-privacy-notice-field-3",
      formId: "form-privacy-notice",
      type: "TEXT",
      formFieldPlacements: [
        {
          formFieldPlacementId: "form-privacy-notice-field-3-placement-1",
          page: 1,
          positionX: 165,
          positionY: 641,
          width: 100,
          height: 50,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false,
        },
      ],
      createdAt: "2024-05-21 00:00:00.000",
    },
    name: {
      formFieldId: "form-privacy-notice-field-4",
      formId: "form-privacy-notice",
      type: "TEXT",
      formFieldPlacements: [
        {
          formFieldPlacementId: "form-privacy-notice-field-4-placement-1",
          page: 1,
          positionX: 125,
          positionY: 726,
          width: 100,
          height: 50,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false,
        },
      ],
      createdAt: "2024-05-21 00:00:00.000",
    },
    signature: {
      formFieldId: "form-privacy-notice-field-5",
      formId: "form-privacy-notice",
      type: "SIGNATURE",
      formFieldPlacements: [
        {
          formFieldPlacementId: "form-privacy-notice-field-5-placement-1",
          page: 1,
          positionX: 350,
          positionY: 690,
          width: 100,
          height: 50,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false,
        },
      ],
      createdAt: "2024-05-21 00:00:00.000",
    },
  },
});
