import * as Sentry from '@sentry/react';
import { useAtomValue } from 'jotai';
import { Fragment, Suspense, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CustomErrorBoundary from '~/components/CustomErrorBoundary';
import KioskModeHook from '~/components/hooks/KioskModeHook';
import config from '~/config';
import { authStateAtom } from '~/state/auth';
import { kioskDealershipAtom } from '~/state/kiosk';

const LoadAtoms = () => {
  useAtomValue(authStateAtom);
  useAtomValue(kioskDealershipAtom);

  return null;
};

/**
 * This hook Chagnes the fav icon in accordance with the environment we are
 * using.
 *
 * @returns
 */
const FavIconHook = () => {
  useEffect(() => {
    let link = document.querySelector<HTMLLinkElement>("link[rel~='icon']");
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.head.appendChild(link);
    }

    if (config.prodEnv) {
      link.href = `/fav/logo-${config.prodEnv}.png`;
    } else if (!config.isProduction) {
      link.href = `/fav/logo-local.png`;
    }
  }, []);

  return null;
};

const App = () => {
  const handleOnError = (error: Error) => {
    Sentry.captureException(error);
    toast.error(`Error: ${error.message}`);
  };

  return (
    <Fragment>
      {/* Toast container somehow need to be initiated before the error boundary else it will not show to popup */}
      <ToastContainer position="bottom-right" />
      <CustomErrorBoundary
        onError={(error, _errorInfo) => {
          handleOnError(error);
        }}
      >
        <Suspense>
          {/* Load atoms first */}
          <LoadAtoms />

          <FavIconHook />
          <KioskModeHook />

          <Outlet />
        </Suspense>
      </CustomErrorBoundary>
    </Fragment>
  );
};

export default App;
