import { privacyDocumentTemplate } from '@thedealersconcierge/lib/pdf-gen/templates/privacy-notice';
import { TFunction } from 'i18next';

export const privacyDocument = (t: TFunction) =>
  privacyDocumentTemplate(
    t('onboarding-privacy-notice', {
      ns: 'files',
      defaultValue: '/docs/privacy-notice.pdf'
    }),
    true
  );
