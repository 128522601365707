import { DocumentTemplate } from '@thedealersconcierge/lib/pdf-gen';

type Fields = 'name' | 'dateTime' | 'deviceId' | 'ipAddress';

export const legalStamps = (
  formId: string,
  pages: number[]
): DocumentTemplate<Fields>['fields'] => {
  return {
    name: {
      formFieldId: `${formId}-field-legal-1`,
      formId,
      demoData: 'John Doe',
      formFieldPlacements: pages.map((page, i) => {
        return {
          formFieldPlacementId: `${formId}-field-legal-1-placement-${i + 1}`,
          positionX: 20,
          positionY: 746,
          page,
          width: 150,
          height: 30,
          textFontSize: 10,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        };
      }),
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    dateTime: {
      formFieldId: `${formId}-field-legal-2`,
      formId,
      demoData: '11/01/2024 4:43 PM',
      formFieldPlacements: pages.map((page, i) => {
        return {
          formFieldPlacementId: `${formId}-field-legal-2-placement-${i + 1}`,
          positionX: 220,
          positionY: 746,
          fontSize: 10,
          page,
          width: 150,
          height: 30,
          textFontSize: 10,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        };
      }),
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    ipAddress: {
      formFieldId: `${formId}-field-legal-3`,
      formId,
      demoData: '555.555.555.555',
      formFieldPlacements: pages.map((page, i) => {
        return {
          formFieldPlacementId: `${formId}-field-legal-3-placement-${i + 1}`,
          positionX: 380,
          positionY: 746,
          fontSize: 10,
          page,
          width: 150,
          height: 30,
          textFontSize: 10,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        };
      }),
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    deviceId: {
      formFieldId: `${formId}-field-legal-4`,
      formId,
      demoData:
        'Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/120.0.0.0 Safari/537.36',
      formFieldPlacements: pages.map((page, i) => {
        return {
          formFieldPlacementId: `${formId}-field-legal-4-placement-${i + 1}`,
          positionX: 20,
          positionY: 766,
          fontSize: 10,
          page,
          width: 590,
          height: 30,
          textFontSize: 10,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        };
      }),
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    }
  };
};
