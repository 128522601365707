import { useQuery } from '@tanstack/react-query';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import logoutAction from '~/actions/logoutAction';
import Button from '~/components/Button';
import Spinner from '~/components/Spinner';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DocumentIcon from '~/components/icons/DocumentIcon';
import customerQuery from '~/queries/customerQuery';
import dealershipQuery from '~/queries/dealershipQuery';
import { mkHardCreditQuery } from '~/queries/hardCreditQuery';
import meQuery from '~/queries/meQuery';
import { Link, useParams } from '~/router';
import ConsumerDashboardHeader from '../../../_components/ConsumerDashboardHeader';
import ModificationForm from './_components/ModificationForm';

const ModificationFormPage = () => {
  const { t } = useTranslation();
  const { transactionId, hardCreditApplicationId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/modification/modificationForm'
  );
  const [loggingOut, setLoggingOut] = useState(false);
  const handleLogout = async () => {
    setLoggingOut(true);
    await logoutAction();
  };
  const { data: meData } = useQuery(meQuery());
  const { data } = useQuery(mkHardCreditQuery(transactionId));
  const { data: dealershipData } = useQuery(
    dealershipQuery({ id: data?.transaction?.dealership?.id })
  );

  const { data: customer } = useQuery(
    customerQuery(transactionId, meData?.me?.user?.id, dealershipSlug)
  );

  const hardCreditApplications = customer?.customer?.hardCreditApplications;
  const hardCreditApplication = hardCreditApplications?.edges?.at(0)?.node;

  const addresses = customer?.customer?.residentialAddresses;
  const employments = customer?.customer?.employments;

  return (
    <div className="flex flex-col h-dvh">
      <ConsumerDashboardHeader
        leftElement={
          <div className="relative">
            <img src="/logo.png" className="h-4" aria-label="Logo" />
          </div>
        }
        centerElement={
          <div className="flex flex-row space-x-2">
            <div className="relative">
              <DocumentIcon className="size-4 md:size-5 icon-primary" />
            </div>

            <h2 className="hidden md:flex items-center">
              {t('Documents & Forms')}
            </h2>

            <h3 className="flex md:hidden items-center">
              {t('Documents & Forms')}
            </h3>
          </div>
        }
        rightElement={
          <Button
            variant="TEXT_ONLY"
            size="SMALL"
            onClick={() => void handleLogout()}
            loading={loggingOut}
            className="!p-0"
          >
            <span className="text-secondary">{t('Logout')}</span>
          </Button>
        }
        showBorder
      />

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center pt-4">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/modification/modificationNotice"
            params={{ transactionId, hardCreditApplicationId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col py-6 md:py-10 space-y-6 w-full items-center overflow-y-scroll h-full">
        {hardCreditApplication && dealershipData && meData ? (
          <Fragment>
            <div className="flex w-full px-6 justify-center">
              <div className="flex flex-col space-y-4 w-full max-w-screen-md bg-primary">
                <div className="space-y-2">
                  <h2>{t('Credit Application')}</h2>

                  <p>{t('Please read the information below<')}</p>
                </div>

                <div className="space-y-2">
                  <p>
                    {t(
                      'Filling out a Credit Application allows the dealer to submit your request for credit to the financial institutions to secure an approval for the loan. If the dealer submits the request for credit, it will be considered a Credit Inquiry.'
                    )}
                  </p>

                  <p>
                    {t(
                      'Compliance Notice: A Hard-Credit Inquiry will only be needed by the dealer when you are at the dealership, have agreed to all the terms and conditions of your Finance or Lease Agreements, and have decided to drive away in your car.'
                    )}
                  </p>
                </div>
              </div>
            </div>

            <ModificationForm
              dealership={dealershipData}
              meData={meData}
              hardCreditApplication={hardCreditApplication}
              addresses={
                addresses?.edges
                  ?.map((e) => e.node ?? {})
                  .filter(
                    (a) =>
                      Boolean(a) &&
                      a.timelinePosition !== undefined &&
                      a.timelinePosition !== null
                  ) ?? [] // We only want residential addresses which means addresses with a timeline position
              }
              employments={employments?.edges?.map((e) => e.node ?? {}) ?? []}
            />
          </Fragment>
        ) : (
          <div className="flex h-full aspect-square justify-center items-center relative">
            <Spinner className="w-10 aspect-square" />
          </div>
        )}
      </div>
    </div>
  );
};

export default ModificationFormPage;
