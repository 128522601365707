import { z } from "zod";

export const socialSecurityNumber = () =>
  z
    .string()
    // Some normalization of the social security number so that we can compare compare them in the database
    // and that external parties understand it
    .transform((dl) =>
      dl ? dl.replace(/[^a-zA-Z0-9]/g, "").toUpperCase() : ""
    )
    // For validation
    .refine(
      (ssn) => {
        // This codec could be used as an optional so we have to check for undefined
        if (ssn === undefined) {
          return true;
        }

        return ssn.length === 9;
      },
      (ssn) => ({
        message: `${ssn} is not a valid social security number`,
      })
    );
