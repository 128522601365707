import {
  GraphQLTypes,
  InputType,
  Selector
} from '~/__generated__/backend/zeus';

export const vehicleSelector = Selector('Vehicle')({
  id: true,
  vin: true,
  model: true,
  make: true,
  year: true,
  registrationCard: {
    id: true,
    url: true
  },
  registrationName: true,
  registrationState: true,
  color: true,
  condition: true,
  bodyType: true,
  mileage: true,
  isUsed: true,
  trim: true,
  stockNumber: true,
  pictureFront: {
    url: true
  },
  pictureBack: {
    url: true
  },
  pictureLeftSide: {
    url: true
  },
  pictureRightSide: {
    url: true
  },
  pictureInterior: {
    url: true
  },
  pictureOdometer: {
    url: true
  },
  pictureVinNumber: {
    url: true
  },
  principalPriorUse: true,
  updatedByDealership: true
});

export type VehicleType = InputType<
  GraphQLTypes['Vehicle'],
  typeof vehicleSelector
>;
