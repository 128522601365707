import { z } from "zod";

export const LifeCycle = z.union([
  z.literal("PRE_PURCHASE"),
  z.literal("POST_PURCHASE"),
]);

export type LifeCycle = z.TypeOf<typeof LifeCycle>;

export const FormSchema = z.object({
  id: z.string().uuid(),
  pdfFileUrl: z.string().url(),
  displayTitle: z.string(),
  adminTitle: z.string(),
  createdAt: z.string(),
});

export type FormSchema = z.TypeOf<typeof FormSchema>;
