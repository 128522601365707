import { gqlMutationClient } from '~/lib/backend';

export const hasExistingUserAction = async (email: string) => {
  const resp = await gqlMutationClient()({
    userExists: [
      {
        email
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationUserExistsSuccess': {
          data: {
            status: true
          }
        }
      }
    ]
  });

  if (resp.userExists?.__typename === 'MutationUserExistsSuccess') {
    return true;
  }

  return false;
};
