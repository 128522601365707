import { FC } from 'react';

const PhoneIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 33 32"
    >
      <title>Phone icon</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.168 1.3335H9.83464C8.36188 1.3335 7.16797 2.5274 7.16797 4.00016V28.0002C7.16797 29.4729 8.36188 30.6668 9.83464 30.6668H23.168C24.6407 30.6668 25.8346 29.4729 25.8346 28.0002V4.00016C25.8346 2.5274 24.6407 1.3335 23.168 1.3335ZM9.83594 4H13.8359C13.8359 4.73638 14.4329 5.33333 15.1693 5.33333H17.8359C18.5723 5.33333 19.1693 4.73638 19.1693 4H23.1693V28H9.83594V4Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default PhoneIcon;
