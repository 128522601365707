import { FC } from 'react';

const DocumentsIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>Documents icon</title>

      <path
        d="M17.1133 1.01367V5.63933H21.7389"
        stroke="currentColor"
        strokeWidth="1.18072"
        strokeMiterlimit="10"
      />

      <path
        d="M17.1148 18.3599H21.7404V5.63933L17.1148 1.01367H6.70703V5.63933"
        stroke="currentColor"
        strokeWidth="1.18072"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />

      <path
        d="M12.4883 5.64014V10.2658H17.1139"
        stroke="currentColor"
        strokeWidth="1.18072"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />

      <path
        d="M12.4898 5.64062H2.08203V22.9868H17.1154V10.2663L12.4898 5.64062Z"
        stroke="currentColor"
        strokeWidth="1.18072"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />

      <path
        d="M5.55078 17.7832H13.6457"
        stroke="currentColor"
        strokeWidth="1.18072"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />

      <path
        d="M5.55078 14.3145H13.6457"
        stroke="currentColor"
        strokeWidth="1.18072"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />

      <path
        d="M5.55078 10.8433H9.02002"
        stroke="currentColor"
        strokeWidth="1.18072"
        strokeMiterlimit="10"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default DocumentsIcon;
