import { DocumentTemplate } from '@thedealersconcierge/lib/pdf-gen';
import { legalStamps } from '@thedealersconcierge/lib/pdf-gen/templates/legal-stamp';
import { TFunction } from 'i18next';

type Fields = 'name' | 'dateTime' | 'deviceId' | 'ipAddress';

export const hardCreditApplicationModification: (
  t: TFunction
) => DocumentTemplate<Fields> = (t: TFunction) => ({
  fileUrl: t('hard-credit-application-modification', {
    ns: 'files',
    defaultValue: '/docs/hard-credit-application-modification.pdf'
  }),
  fields: legalStamps('form-hard-credit-application-modification', [0])
});
