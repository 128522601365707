import { z } from "zod";
import { FormSchema } from "./form";
import { FormBuilderFormFieldPlacementSchema } from "./formFieldPlacement";

export const FormFieldType = z.union([
  z.literal("TEXT"),
  z.literal("SIGNATURE"),
  z.literal("CHARACTER_LIST"),
  z.literal("CHECKBOX"),
  z.literal("IMAGE"),
]);
export type FormFieldType = z.infer<typeof FormFieldType>;

export const TransactionRoleSchema = z.union([
  z.literal("CO_BUYER"),
  z.literal("BUYER"),
  z.literal("SALES_PERSON"),
]);
export type TransactionRoleSchema = z.infer<typeof TransactionRoleSchema>;

export const FormFieldSchema = z.object({
  id: z.string().uuid(),
  formFieldId: z.string(),
  formId: z.string().uuid(),
  form: FormSchema,
  type: FormFieldType,
  prefillPath: z.string(),
  characterListLength: z.number().int(),
  demoData: z.string(),
  fixedValue: z.string(),
  createdAt: z.string(),
  enteredBy: TransactionRoleSchema,
});
export type FormFieldSchema = z.TypeOf<typeof FormFieldSchema>;

export const FormBuilderFormFieldSchema = FormFieldSchema.pick({
  formFieldId: true,
  formId: true,
  type: true,
  prefillPath: true,
  characterListLength: true,
  demoData: true,
  fixedValue: true,
  createdAt: true,
  enteredBy: true,
})
  .partial({
    characterListLength: true,
    demoData: true,
    fixedValue: true,
    enteredBy: true,
  })
  .extend({
    prefillPath: z.string().nullish(),
    enteredBy: TransactionRoleSchema.nullish(),
    characterListLength: z.number().int().nullish(),
    demoData: z.string().nullish(),
    fixedValue: z.string().nullish(),
    formFieldPlacements: z.array(FormBuilderFormFieldPlacementSchema).nullish(),
  });

export type FormBuilderFormFieldSchema = z.TypeOf<
  typeof FormBuilderFormFieldSchema
>;
