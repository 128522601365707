import { gqlQueryClient } from '~/lib/backend';
import { hardCreditApplicationSelector } from '~/querySelectors/hardCreditApplication';

export const hardCreditApplicationQuery = (
  transactionId: string,
  hardCreditApplicationId: string
) => ({
  queryKey: [''],
  queryFn: async () =>
    gqlQueryClient()({
      transaction: [
        {
          id: transactionId
        },
        {
          // We select for both, as we do filtering, only the one that has the hard
          // credit applicaiton will see the returned value
          coBuyer: {
            hardCreditApplications: [
              {
                first: 1,
                filter: {
                  id: {
                    equals: hardCreditApplicationId
                  }
                }
              },
              {
                edges: {
                  node: hardCreditApplicationSelector
                }
              }
            ]
          },
          buyer: {
            hardCreditApplications: [
              {
                first: 1,
                filter: {
                  id: {
                    equals: hardCreditApplicationId
                  }
                }
              },
              {
                edges: {
                  node: hardCreditApplicationSelector
                }
              }
            ]
          }
        }
      ]
    })
});
