import { ValidationError } from '@tanstack/react-form';
import classNames from 'classnames';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

const TextInput: FC<{
  value?: string;
  fieldName?: string;
  labelText: string;
  isEmail?: boolean;
  subtitleText?: string;
  error?: ValidationError;
  disabled?: boolean;
  placeholder?: string;
  required?: boolean;
  containerClassName?: string;
  inputClassName?: string;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  dataTestId?: string;
}> = ({
  value,
  fieldName,
  labelText,
  isEmail,
  subtitleText,
  error,
  disabled,
  placeholder,
  required,
  containerClassName,
  inputClassName,
  onChange,
  dataTestId
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames('flex flex-col space-y-2', containerClassName)}>
      <div className="relative flex flex-row border-b-[0.5px] z-0 text-body-1">
        <label htmlFor={fieldName} className="hidden">
          {labelText}
        </label>

        <input
          data-test-id={dataTestId}
          type={isEmail ? 'email' : 'text'}
          name={fieldName}
          className={classNames(
            'w-full h-8 block px-2 appearance-none outline-none placeholder:text-inactive bg-transparent disabled:disabled-input',
            inputClassName
          )}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
          aria-placeholder={placeholder}
          disabled={disabled}
        />
      </div>

      <div className="flex flex-row">
        {subtitleText && !error && (
          <p
            className={classNames('text-body-3 ml-2 text-tertiary', {
              'text-inactive': disabled // We can use the "disabled:" TailwindCSS only on inputs
            })}
          >
            {subtitleText}
          </p>
        )}

        {required && !error && (
          <p
            className={classNames('text-body-3 ml-2 text-tertiary italic', {
              'text-inactive': disabled // We can use the "disabled:" TailwindCSS only on inputs
            })}
          >
            {t('Required')}
          </p>
        )}

        {error && <p className="text-body-3 ml-2 text-negative">{error}</p>}
      </div>
    </div>
  );
};

export default TextInput;
