import classNames from 'classnames';
import { FC } from 'react';
import { twMerge } from 'tailwind-merge';
import { SpinnerColor, SpinnerSize } from './spinnerConfig';

const Spinner: FC<{
  size: SpinnerSize;
  color: SpinnerColor;
  className?: string;
}> = ({ size, color, className }) => {
  return (
    <div
      className={twMerge(
        classNames('relative', {
          'size-3': size === 'XSMALL',
          'size-4': size === 'SMALL',
          'size-6': size === 'MEDIUM',
          'size-12': size === 'LARGE'
        }),
        className
      )}
    >
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="absolute inset-0 animate-spin"
      >
        <path
          d="M48 24C48 37.2548 37.2548 48 24 48C10.7452 48 0 37.2548 0 24C0 10.7452 10.7452 0 24 0C37.2548 0 48 10.7452 48 24ZM6 24C6 33.9411 14.0589 42 24 42C33.9411 42 42 33.9411 42 24C42 14.0589 33.9411 6 24 6C14.0589 6 6 14.0589 6 24Z"
          fill="#E5E5E6"
        />
        <path
          d="M47.1822 30.2117C46.202 33.8701 44.3698 37.2445 41.8355 40.0591C39.3012 42.8738 36.1367 45.0486 32.6008 46.4059C29.0649 47.7632 25.258 48.2644 21.4913 47.8685C17.7246 47.4726 14.1051 46.1909 10.9287 44.1281L14.1965 39.0961C16.5788 40.6432 19.2935 41.6045 22.1185 41.9014C24.9435 42.1983 27.7987 41.8224 30.4506 40.8044C33.1025 39.7865 35.4759 38.1553 37.3766 36.0443C39.2773 33.9334 40.6515 31.4025 41.3867 28.6587L47.1822 30.2117Z"
          fill={color === 'BLUE' ? '#2270EC' : '#73757C'}
        />
      </svg>
    </div>
  );
};

export default Spinner;
