import { UseQueryOptions } from '@tanstack/react-query';
import {
  GraphQLTypes,
  InputType,
  Selector
} from '~/__generated__/backend/zeus';
import { gqlQueryClient } from '~/lib/backend';
import { queryClient } from '~/main';

import { vehicleSelector } from '~/querySelectors/vehicle';

const queryKey = (transactionId: string) => ['hardCredit', transactionId];

const selector = (transactionId: string) =>
  Selector('Query')({
    transaction: [
      {
        id: transactionId
      },
      {
        id: true,
        status: true,
        buyerId: true,
        coBuyerId: true,
        customerSharedData: {
          buyerFirstName: true,
          buyerLastName: true,
          buyerEmail: true,
          buyerPhoneNumber: true,
          buyerName: true,
          coBuyerFirstName: true,
          coBuyerLastName: true,
          coBuyerEmail: true,
          coBuyerPhoneNumber: true,
          coBuyerName: true
        },
        dealership: {
          id: true,
          name: true
        },
        vehicle: vehicleSelector,
        tradeVehicle: vehicleSelector,
        salesManager: {
          firstName: true,
          lastName: true,
          email: true
        },
        salesPerson: {
          firstName: true,
          lastName: true,
          email: true
        },
        financeType: true,
        price: true,
        downPayment: true,
        createdAt: true,
        hardCreditApplDmsSubmittedAt: true
      }
    ]
  });
export type HardCreditQueryType = InputType<
  GraphQLTypes['Query'],
  ReturnType<typeof selector>
>;

/**
 * Currently this query over-fetches. This needs to be fixed. The reason why we
 * ended at this one, is becaus severat smaller queries
 *
 * @param transactionId
 * @returns
 */
export const mkHardCreditQuery = (
  transactionId: string
): UseQueryOptions<HardCreditQueryType> => {
  return {
    queryKey: queryKey(transactionId),
    queryFn: async () => gqlQueryClient()(selector(transactionId))
  };
};

export const resetHardCreditQuery = async (transactionId: string) => {
  await queryClient.resetQueries({
    queryKey: queryKey(transactionId)
  });
};
