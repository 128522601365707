import { FC } from 'react';

const SignatureIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 22 18"
    >
      <title>Signature icon</title>

      <path
        d="M11.75 1C11.75 0.585786 11.4142 0.25 11 0.25C10.5858 0.25 10.25 0.585786 10.25 1H11.75ZM10.4697 15.7526C10.7626 16.0454 11.2374 16.0454 11.5303 15.7526L16.3033 10.9796C16.5962 10.6867 16.5962 10.2118 16.3033 9.91892C16.0104 9.62603 15.5355 9.62603 15.2426 9.91892L11 14.1616L6.75736 9.91892C6.46447 9.62603 5.98959 9.62603 5.6967 9.91892C5.40381 10.2118 5.40381 10.6867 5.6967 10.9796L10.4697 15.7526ZM10.25 1V15.2222H11.75V1H10.25Z"
        fill="currentColor"
      />

      <path
        d="M1 17H21"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SignatureIcon;
