import { AuthenticationDetails, CognitoUser } from 'amazon-cognito-identity-js';
import store from '~/lib/store';
import { userPool } from '~/lib/userpool';
import { authStateAtom, cognitoUserAtom } from '~/state/auth';

export const cognitoRequestOtpAction = async (
  email: string
): Promise<'OK' | 'CUSTOM_CHALLANGE_REQUIRED'> => {
  if (!userPool) {
    throw new Error('Cognito user pool was not loaded');
  }

  // Construct a user
  const user = new CognitoUser({
    Username: email,
    Pool: userPool
  });
  user.setAuthenticationFlowType('CUSTOM_AUTH');

  store.instance.set(cognitoUserAtom, user);

  // We login with email and password here
  const authDetails = new AuthenticationDetails({
    Username: email

    // Currently we don't use this, as it needs to be modelled as two flows
    // to be able to fail on Sms and success on email (of the other way aroaund)
    // ClientMetadata: {
    //   Flow: 'Verify'
    // }
  });

  return new Promise<'OK' | 'CUSTOM_CHALLANGE_REQUIRED'>((resolve, reject) => {
    user.initiateAuth(authDetails, {
      // This one is expected to be invoked
      customChallenge: () => resolve('CUSTOM_CHALLANGE_REQUIRED'),
      onFailure: (failure) => {
        reject(failure);
      },

      // This will never happen, but it needs to be here.
      onSuccess: async (resp, confirmationNecessary) => {
        store.instance.set(authStateAtom, {
          accessToken: resp.getAccessToken().getJwtToken(),
          idToken: resp.getIdToken().getJwtToken(),
          refreshToken: resp.getRefreshToken().getToken()
        });
        resolve('OK');
      }
    });
  });
};
