import store from '~/lib/store';
import { queryClient } from '~/main';
import { authStateAtom, cognitoUserAtom } from '~/state/auth';

export default function logoutAction() {
  // We can not just clear everything as we don't want to loose the kiosk mode
  store.instance.set(authStateAtom, null);

  // Remove all data that is present cached
  queryClient.clear();

  // Sign out the cognito user
  const user = store.instance.get(cognitoUserAtom);
  user?.signOut();

  // This will reset all atoms that are not stored
  window.location.href = '/';
}
