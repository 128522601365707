import { useQuery } from '@tanstack/react-query';
import { format, isBefore } from 'date-fns';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import {
  OnboardingStep,
  OrderBy,
  Selector
} from '~/__generated__/backend/zeus';
import logoutAction from '~/actions/logoutAction';
import Button from '~/components/Button';
import { gqlQueryClient } from '~/lib/backend';
import { useNavigate } from '~/router';
import { kioskDealershipAtom } from '~/state/kiosk';
import ConsumerDashboardHeader from './[dealershipSlug]/[transactionId]/_components/ConsumerDashboardHeader';
import { navigateToTransaction } from './_utils';

const transactionSelector = Selector('Transaction')({
  id: true,
  buyerId: true,
  createdAt: true,
  customerSharedData: {
    buyerOnboardingStep: true,
    coBuyerOnboardingStep: true
  },
  dealership: {
    name: true,
    slug: true
  },
  vehicle: {
    make: true,
    model: true
  },
  logs: [
    {
      first: 1,
      orderBy: {
        createdAt: OrderBy.Desc
      }
    },
    {
      edges: {
        node: {
          createdAt: true
        }
      }
    }
  ]
});

export default function TransactionOverviewPage() {
  const { t } = useTranslation();
  const location = useLocation();
  const doRedirect = location?.state?.doRedirect;
  const redirectTo = location?.state?.redirectTo;
  const dealerKiosk = useAtomValue(kioskDealershipAtom);

  const [loggingOut, setLoggingOut] = useState(false);
  const handleLogout = async () => {
    setLoggingOut(true);
    await logoutAction();
  };

  const navigate = useNavigate();

  const { data: meData } = useQuery({
    queryKey: ['transactionOverview', dealerKiosk?.dealershipSlug],
    queryFn: async () =>
      gqlQueryClient()({
        me: {
          user: {
            id: true,
            buyerFor: [
              {
                filter: {
                  dealership: dealerKiosk?.dealershipSlug
                    ? {
                        slug: { equals: dealerKiosk.dealershipSlug }
                      }
                    : undefined
                }
              },
              {
                edges: {
                  node: transactionSelector
                }
              }
            ],
            coBuyerFor: [
              {
                filter: {
                  dealership: dealerKiosk?.dealershipSlug
                    ? {
                        slug: { equals: dealerKiosk.dealershipSlug }
                      }
                    : undefined
                }
              },
              {
                edges: {
                  node: transactionSelector
                }
              }
            ]
          }
        }
      })
  });

  const buyerFor = meData?.me?.user?.buyerFor?.edges;
  const coBuyerFor = meData?.me?.user?.coBuyerFor?.edges;
  const allTransactions = (
    buyerFor?.map((t) => ({
      role: 'BUYER',
      ...t.node
    })) ?? []
  )
    .concat(
      coBuyerFor?.map((t) => ({
        role: 'CO_BUYER',
        ...t.node
      })) ?? []
    )
    .sort((t1, t2) => {
      const t1LastUpdated = t1.logs?.edges?.[0]?.node?.createdAt;
      const t2LastUpdated = t2.logs?.edges?.[0]?.node?.createdAt;

      if (!t1LastUpdated && !t2LastUpdated) {
        return 0;
      }

      if (t1LastUpdated && !t2LastUpdated) {
        return -1;
      }

      if (!t1LastUpdated && t2LastUpdated) {
        return 1;
      }

      return isBefore(t1LastUpdated ?? new Date(), t2LastUpdated ?? new Date()) // The type system doesn't pick up our preceding checks. Therefore, we have to provide the default value but that shouldn't be used.
        ? 1
        : -1;
    });

  // Generally, don't use useEffect.
  // Here we setup a special case where we allow redirecting when there is
  // only a single transaction in the list. This is to make the login flow
  // a bit more consolidated
  useEffect(() => {
    // Explicit direct to an ID
    if (redirectTo) {
      const transCoBuyer = coBuyerFor?.find((t) => t.node?.id === redirectTo);
      const transBuyer = buyerFor?.find((t) => t.node?.id === redirectTo);
      // Assume that the user is not both buyer and co-buyer (Which I find reasonable)
      if (transCoBuyer || transBuyer) {
        const step =
          transCoBuyer?.node?.customerSharedData?.coBuyerOnboardingStep ??
          transBuyer?.node?.customerSharedData?.buyerOnboardingStep;
        const slug = (transCoBuyer ?? transBuyer)?.node?.dealership?.slug;

        navigateToTransaction(redirectTo, slug ?? 'slug', navigate, step);
      }
    }

    // Redirect when there is only a single transaction
    if (doRedirect && allTransactions.length === 1) {
      if (coBuyerFor?.at(0)) {
        const transactionId = coBuyerFor.at(0)?.node?.id ?? 'never';

        const step =
          coBuyerFor.at(0)?.node?.customerSharedData?.coBuyerOnboardingStep;
        const slug = coBuyerFor.at(0)?.node?.dealership?.slug;

        navigateToTransaction(transactionId, slug ?? 'slug', navigate, step);
      } else if (buyerFor?.at(0)) {
        const transactionId = buyerFor.at(0)?.node?.id ?? 'never';

        const step =
          buyerFor.at(0)?.node?.customerSharedData?.buyerOnboardingStep;
        const slug = buyerFor.at(0)?.node?.dealership?.slug;

        navigateToTransaction(transactionId, slug ?? 'slug', navigate, step);
      }
    }
  }, [doRedirect, redirectTo, allTransactions.length]);

  return (
    <div className="flex flex-col min-h-dvh">
      <ConsumerDashboardHeader
        leftElement={
          <div className="relative">
            <img src="/logo.png" className="h-4" aria-label="Logo" />
          </div>
        }
        centerElement={<h3>{t('Transactions')}</h3>}
        rightElement={
          <Button
            variant="TEXT_ONLY"
            onClick={() => void handleLogout()}
            loading={loggingOut}
            className="!py-0 pr-0 h-fit"
          >
            <span className="text-secondary">{t('Logout')}</span>
          </Button>
        }
      />

      <div className="flex flex-1 justify-center bg-secondary py-4 px-6">
        <div className="bg-primary w-full h-fit overflow-y-scroll max-w-screen-lg p-4 rounded-lg shadow-border space-y-4">
          <div className="flex flex-row justify-between">
            <div className="flex flex-row space-x-2">
              <h2>{t('Transaction Overview')}</h2>
            </div>
          </div>

          <ul className="flex flex-col space-y-2">
            <div className="grid grid-cols-12 rounded-lg shadow-border bg-secondary px-4 py-2">
              <p className="col-span-5 font-bold">{t('Transaction')}</p>
              <p className="col-span-3 font-bold">{t('Dealership')}</p>
              <p className="col-span-1 font-bold">{t('Role')}</p>
              <p className="col-span-3 font-bold">{t('Last Updated')}</p>
            </div>

            {allTransactions.map((tr) => {
              const date = tr.createdAt
                ? format(tr.createdAt, 'do MMM yyyy')
                : undefined;
              const vehicleName = tr.vehicle
                ? ` | ${tr.vehicle.make} ${tr.vehicle.model}`
                : '';
              const transactionId = tr.id;
              const lastUpdated = tr.logs?.edges?.[0]?.node?.createdAt
                ? format(tr.logs.edges[0].node.createdAt, 'do MMM yyyy - p')
                : undefined;

              return (
                <div
                  key={transactionId}
                  onClick={() => {
                    navigateToTransaction(
                      transactionId ?? 'no-transaction-id',
                      tr.dealership?.slug ?? 'slug',
                      navigate,
                      OnboardingStep.STEP1
                      // TODO
                      // meData?.me.user.id === t?.buyerId
                      //   ? t?.step
                      //   : t?.stepCoBuyer
                    );
                  }}
                  className="grid grid-cols-12 cursor-pointer rounded-lg shadow-border bg-blue-50 px-4 py-2 hover:bg-blue-100"
                >
                  <div className="col-span-5">
                    {date}
                    {vehicleName}
                  </div>
                  <div className="col-span-3">{tr.dealership?.name ?? ''}</div>
                  <div className="col-span-1">
                    {t(tr.role === 'BUYER' ? 'Buyer' : 'Co-Buyer')}
                  </div>
                  <div className="col-span-3">{lastUpdated}</div>
                </div>
              );
            })}
          </ul>

          <div className="grid grid-cols-1 md:grid-cols-4 gap-4">
            <div className="grid md:grid-cols-2 md:col-span-2 gap-4"></div>
          </div>
        </div>
      </div>
    </div>
  );
}
