import { UseQueryOptions } from '@tanstack/react-query';
import {
  GraphQLTypes,
  InputType,
  Selector
} from '~/__generated__/backend/zeus';
import { gqlQueryClient } from '~/lib/backend';

const queryKey = (dealership: { id?: string; slug?: string }) => [
  'dealership',
  dealership.id ?? dealership.slug
];

// Define select here to derive typing
const selector = (dealership: { id?: string; slug?: string }) =>
  Selector('Query')({
    dealership: [
      dealership,
      {
        hasEnabledPrequalFormService: true,
        id: true,
        name: true,
        website: true,
        logoUrl: true,
        slug: true,
        supportedLanguages: true,
        salesPeople: {
          label: true,
          value: true
        }
      }
    ]
  });
export type DealershipQueryType = InputType<
  GraphQLTypes['Query'],
  ReturnType<typeof selector>
>;

/**
 * This query can be used in all react components. The reason why we store it
 * on the outermost level her (and not just the selector) is to ensure that we
 * reuse the query selector.
 *
 * @returns
 */
export default function dealershipQuery(dealership: {
  id?: string;
  slug?: string;
}): UseQueryOptions<DealershipQueryType> {
  return {
    // Disable the query when transactionId or userId is not provided
    enabled: Boolean(dealership.id || dealership.slug),
    queryKey: queryKey(dealership),
    queryFn: () => {
      return gqlQueryClient()(selector(dealership));
    }
  };
}
