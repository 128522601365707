import { FC } from 'react';

const ProfileCircleIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 37 37"
    >
      <title>Profile circle icon</title>

      <path
        d="M18.3333 0C8.20417 0 0 8.20417 0 18.3333C0 28.4625 8.20417 36.6667 18.3333 36.6667C28.4625 36.6667 36.6667 28.4625 36.6667 18.3333C36.6667 8.20417 28.4625 0 18.3333 0ZM18.3333 5.5C21.3675 5.5 23.8333 7.96583 23.8333 11C23.8333 14.0433 21.3675 16.5 18.3333 16.5C15.2992 16.5 12.8333 14.0433 12.8333 11C12.8333 7.96583 15.2992 5.5 18.3333 5.5ZM18.3333 31.5333C13.7408 31.5333 9.7075 29.1867 7.33333 25.63C7.37917 21.9908 14.6758 19.9833 18.3333 19.9833C21.9908 19.9833 29.2783 21.9908 29.3333 25.63C26.9592 29.1867 22.9258 31.5333 18.3333 31.5333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default ProfileCircleIcon;
