import { FC } from 'react';

const CircleCheckMarkIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 40 40"
    >
      <path
        d="M36.6668 18.4667V20C36.6648 23.594 35.501 27.0911 33.3491 29.9697C31.1971 32.8483 28.1723 34.9541 24.7258 35.9732C21.2792 36.9922 17.5956 36.8698 14.2243 35.6243C10.853 34.3788 7.97459 32.0768 6.01843 29.0618C4.06227 26.0467 3.13315 22.4801 3.36962 18.8939C3.6061 15.3076 4.99551 11.8939 7.33063 9.16179C9.66575 6.4297 12.8215 4.52564 16.3271 3.73357C19.8328 2.94151 23.5006 3.30389 26.7835 4.76667"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M36.6667 6.66669L20 23.35L15 18.35"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CircleCheckMarkIcon;
