import { useForm } from '@tanstack/react-form';
import classNames from 'classnames';
import { FC, useState } from 'react';
import Button from '~/components/Button';
import DropDown from '~/components/inputs/DropDown';
import PhoneNumberInput from '~/components/inputs/PhoneNumberInput';
import TextInput from '~/components/inputs/TextInput';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import { HardCreditApplicationType } from '~/querySelectors/hardCreditApplication';

const PersonalReferenceForm: FC<{
  hardCreditApplication: HardCreditApplicationType;
  // We have the onSave function has a prop because this only updates the local state of the updated hard credit application in the parent component
  onSave: (values: {
    personalReferenceFirstName: string;
    personalReferenceMiddleName: string;
    personalReferenceLastName: string;
    personalReferenceRelationship: string;
    personalReferencePhoneNumber: string;
    personalReferenceAddressStreet: string;
    personalReferenceAddressApartmentDetails: string;
    personalReferenceAddressZipCode: string;
    personalReferenceAddressCity: string;
    personalReferenceAddressState: string;
  }) => void;
}> = ({ hardCreditApplication, onSave }) => {
  const [editing, setEditing] = useState(false);
  const handleEdit = () => {
    setEditing(true);
  };
  const form = useForm({
    defaultValues: {
      personalReferenceFirstName:
        hardCreditApplication.personalReferenceFirstName ?? '',
      personalReferenceMiddleName:
        hardCreditApplication.personalReferenceMiddleName ?? '',
      personalReferenceLastName:
        hardCreditApplication.personalReferenceLastName ?? '',
      personalReferenceRelationship:
        hardCreditApplication.personalReferenceRelationship ?? '',
      personalReferencePhoneNumber:
        hardCreditApplication.personalReferencePhoneNumber ?? '',
      personalReferenceAddressStreet:
        hardCreditApplication.personalReferenceAddress?.street ?? '',
      personalReferenceAddressApartmentDetails:
        hardCreditApplication.personalReferenceAddress?.apartmentDetails ?? '',
      personalReferenceAddressZipCode:
        hardCreditApplication.personalReferenceAddress?.zipCode ?? '',
      personalReferenceAddressCity:
        hardCreditApplication.personalReferenceAddress?.city ?? '',
      personalReferenceAddressState:
        hardCreditApplication.personalReferenceAddress?.state ?? ''
    },
    onSubmit: (values) => {
      onSave(values);
      setEditing(false);
    }
  });
  const handleDiscard = () => {
    setEditing(false);
    form.reset();
  };

  return (
    <div
      className={classNames('flex flex-col items-center w-full space-y-4', {
        'px-6': !editing
      })}
    >
      {!editing && (
        <div className="flex flex-col space-y-6 p-4 rounded-lg shadow-border w-full max-w-screen-md px-6">
          <div className="flex flex-row w-full justify-between items-center">
            <h3>Personal Reference</h3>

            <Button
              size="SMALL"
              variant="TEXT_ONLY"
              onClick={() => handleEdit()}
            >
              Edit
            </Button>
          </div>

          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">First Name</p>

              <p className="text-secondary">
                {hardCreditApplication.personalReferenceFirstName?.length
                  ? hardCreditApplication.personalReferenceFirstName
                  : '-'}
              </p>
            </div>

            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">Middle Name</p>

              <p className="text-secondary">
                {hardCreditApplication.personalReferenceMiddleName?.length
                  ? hardCreditApplication.personalReferenceMiddleName
                  : '-'}
              </p>
            </div>

            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">Last Name</p>

              <p className="text-secondary">
                {hardCreditApplication.personalReferenceLastName?.length
                  ? hardCreditApplication.personalReferenceLastName
                  : '-'}
              </p>
            </div>

            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">Relationship</p>

              <p className="text-secondary">
                {hardCreditApplication.personalReferenceRelationship?.length
                  ? hardCreditApplication.personalReferenceRelationship
                  : '-'}
              </p>
            </div>

            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">Phone Number</p>

              <p className="text-secondary">
                {hardCreditApplication.personalReferencePhoneNumber?.length
                  ? hardCreditApplication.personalReferencePhoneNumber
                  : '-'}
              </p>
            </div>

            <div className="space-y-2">
              <p className="text-body-2 text-tertiary">Address</p>

              <p className="text-secondary">
                {hardCreditApplication.personalReferenceAddress?.fullAddress ??
                  '-'}
              </p>
            </div>
          </div>
        </div>
      )}

      {editing && (
        <form.Provider>
          <div className="flex w-full justify-center bg-secondary border-y border-secondary p-6">
            <form
              className="flex flex-col space-y-6 w-full max-w-screen-md"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                void form.handleSubmit();
              }}
            >
              <div className="flex flex-row w-full justify-between">
                <h3>Personal Reference</h3>

                <div className="flex flex-row items-center">
                  <Button type="submit" size="SMALL" variant="TEXT_ONLY">
                    Save
                  </Button>

                  <span className="icon-tertiary text-sm">|</span>

                  <Button
                    size="SMALL"
                    variant="TEXT_ONLY"
                    onClick={() => handleDiscard()}
                  >
                    Discard
                  </Button>
                </div>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-8 gap-4 items-end w-full">
                <form.Field name="personalReferenceFirstName">
                  {(field) => {
                    return (
                      <TextInput
                        fieldName={field.name}
                        value={field.state.value}
                        labelText="First Name"
                        placeholder="First Name"
                        subtitleText="First Name"
                        error={field.state.meta.touchedErrors.at(0)}
                        onChange={(e) => field.handleChange(e.target.value)}
                        containerClassName="md:col-span-3"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="personalReferenceMiddleName">
                  {(field) => {
                    return (
                      <TextInput
                        fieldName={field.name}
                        value={field.state.value}
                        labelText="Middle Name"
                        subtitleText="Middle Name"
                        placeholder="Middle Name"
                        error={field.state.meta.touchedErrors.at(0)}
                        onChange={(e) => field.handleChange(e.target.value)}
                        containerClassName="md:col-span-2"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="personalReferenceLastName">
                  {(field) => {
                    return (
                      <TextInput
                        fieldName={field.name}
                        value={field.state.value}
                        labelText="Last Name"
                        placeholder="Last Name"
                        subtitleText="Last Name"
                        error={field.state.meta.touchedErrors.at(0)}
                        onChange={(e) => field.handleChange(e.target.value)}
                        containerClassName="md:col-span-3"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="personalReferenceRelationship">
                  {(field) => {
                    return (
                      <TextInput
                        fieldName={field.name}
                        value={field.state.value}
                        labelText="Relationship"
                        subtitleText="Relationship"
                        placeholder="Relationship"
                        error={field.state.meta.touchedErrors.at(0)}
                        onChange={(e) => field.handleChange(e.target.value)}
                        containerClassName="md:col-span-4"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="personalReferencePhoneNumber">
                  {(field) => {
                    return (
                      <PhoneNumberInput
                        fieldName={field.name}
                        value={field.state.value}
                        subtitleText="Phone Number"
                        placeholder="Phone Number"
                        onChange={(value) =>
                          field.handleChange(value?.toString() ?? '')
                        }
                        onBlur={field.handleBlur}
                        containerClassName="md:col-span-4"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="personalReferenceAddressStreet">
                  {(field) => {
                    return (
                      <TextInput
                        fieldName={field.name}
                        value={field.state.value}
                        labelText="Street"
                        subtitleText="Street"
                        placeholder="Street"
                        error={field.state.meta.touchedErrors.at(0)}
                        onChange={(e) => field.handleChange(e.target.value)}
                        containerClassName="md:col-span-4"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="personalReferenceAddressApartmentDetails">
                  {(field) => {
                    return (
                      <TextInput
                        fieldName={field.name}
                        value={field.state.value}
                        labelText="Apartment Details"
                        subtitleText="Apartment Details"
                        placeholder="Suite, Apt #"
                        error={field.state.meta.touchedErrors.at(0)}
                        onChange={(e) => field.handleChange(e.target.value)}
                        containerClassName="md:col-span-4"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="personalReferenceAddressZipCode">
                  {(field) => {
                    return (
                      <TextInput
                        fieldName={field.name}
                        value={field.state.value}
                        labelText="Zip Code"
                        subtitleText="Zip Code"
                        placeholder="Zip Code"
                        error={field.state.meta.touchedErrors.at(0)}
                        onChange={(e) => field.handleChange(e.target.value)}
                        containerClassName="md:col-span-3"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="personalReferenceAddressCity">
                  {(field) => {
                    return (
                      <TextInput
                        fieldName={field.name}
                        value={field.state.value}
                        labelText="City"
                        subtitleText="City"
                        placeholder="City"
                        error={field.state.meta.touchedErrors.at(0)}
                        onChange={(e) => field.handleChange(e.target.value)}
                        containerClassName="md:col-span-2"
                      />
                    );
                  }}
                </form.Field>

                <form.Field name="personalReferenceAddressState">
                  {(field) => {
                    return (
                      <DropDown
                        fieldName={field.name}
                        value={field.state.value}
                        options={stateOptions}
                        labelText="State"
                        placeholder="State"
                        subtitleText="State"
                        error={field.state.meta.touchedErrors.at(0)}
                        onChange={(e) => field.handleChange(e.target.value)}
                        containerClassName="md:col-span-3"
                      />
                    );
                  }}
                </form.Field>
              </div>
            </form>
          </div>
        </form.Provider>
      )}
    </div>
  );
};

export default PersonalReferenceForm;
