import {
  GraphQLTypes,
  InputType,
  Selector
} from '~/__generated__/backend/zeus';

export const addressSelector = Selector('Address')({
  id: true,
  street: true,
  apartmentDetails: true,
  zipCode: true,
  state: true,
  city: true,
  fullAddress: true,
  housingStatus: true,
  monthlyPayment: true,
  durationYears: true,
  durationMonths: true,
  timelinePosition: true
});

export type AddressType = InputType<
  GraphQLTypes['Address'],
  typeof addressSelector
>;
