import { VehiclePrincipalPriorUse } from '~/__generated__/backend/zeus';

export const getReadablePrincipalPriorUse = (
  principalPriorUse: VehiclePrincipalPriorUse
): string => {
  const principalPriorUseMap: { [key in VehiclePrincipalPriorUse]: string } = {
    PRIVATE_PASSENGER_VEHICLE: 'Private Passenger Vehicle',
    RENTAL_VEHICLE: 'Rental vehicle',
    TAXI: 'Taxi',
    LIVERY_VEHICLE: 'Livery vehicle',
    COMMERCIAL: 'Commercial',
    FLEET: 'Fleet',
    DEMO: 'Demo',
    OTHER: 'Other'
  };

  return principalPriorUseMap[principalPriorUse];
};
