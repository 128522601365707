import {
  GraphQLTypes,
  InputType,
  Selector
} from '~/__generated__/backend/zeus';

export const userSelector = Selector('User')({
  id: true,
  email: true,
  phoneNumber: true,
  firstName: true,
  lastName: true
});

export type UserType = InputType<GraphQLTypes['User'], typeof userSelector>;
