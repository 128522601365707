import { useForm } from '@tanstack/react-form';
import { Fragment, useCallback, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Button from '~/components/Button';
import Header from '~/components/Header';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import PhoneNumberInput from '~/components/inputs/PhoneNumberInput';
import TextInput from '~/components/inputs/TextInput';
import { gqlMutationClient } from '~/lib/backend';
import { queryClient } from '~/main';
import { resetMeQuery } from '~/queries/meQuery';
import { useNavigate, useParams } from '~/router';

const CoBuyerPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/coBuyer'
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const [step, setStep] = useState<1 | 2>(1);
  const handleGoToNextStep = () => {
    setStep(2);
  };
  const handleGoBack = useCallback(() => {
    if (step === 1) {
      handleCancel();
    } else {
      setStep(1);
    }
  }, [step]);
  const coBuyerForm = useForm({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: ''
    },
    onSubmit: async (values) => {
      setIsSubmitting(true);
      setError(undefined);

      const response = await gqlMutationClient()({
        createCoBuyer: [
          {
            transactionId,
            coBuyer: values
          },
          {
            __typename: true,
            '...on GraphQLError': {
              message: true
            },
            '...on MutationCreateCoBuyerSuccess': {
              data: {
                status: true
              }
            }
          }
        ]
      });

      if (
        !response.createCoBuyer ||
        response.createCoBuyer.__typename === 'GraphQLError'
      ) {
        setError(response.createCoBuyer?.message ?? 'Unexpected error');
      } else {
        await Promise.all([
          resetMeQuery(),
          queryClient.resetQueries({
            queryKey: ['transaction', transactionId]
          })
        ]);

        navigate('/dashboard/:dealershipSlug/:transactionId', {
          params: { transactionId, dealershipSlug }
        });
      }

      setIsSubmitting(false);
    }
  });

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title={t('Co-Buyer')}
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            {t('Cancel')}
          </button>
        }
        currentStep={step}
        totalSteps={2}
      />

      <div className="flex w-full max-w-screen-md z-30 self-center pt-4 px-6 lg:px-0">
        <button onClick={handleGoBack}>
          <div className="relative">
            <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
          </div>
        </button>
      </div>

      <div className="flex flex-col items-center overflow-y-scroll px-6 md:px-0 h-full">
        <div className="flex flex-col max-w-screen-md py-6 md:py-10 space-y-8 md:space-y-16 w-full">
          <div className="space-y-8">
            <h2>
              {step === 1
                ? t('Step 1: Co-Buyer Invitation Link')
                : t('Step 2: Co-Buyer Information')}
            </h2>

            {step === 1 && (
              <Fragment>
                <div className="space-y-4">
                  <p>
                    {t(
                      "The next step prompts you to input your co-buyer's first and last name, email, and cell phone number."
                    )}
                  </p>
                  <p>
                    {t(
                      'The system will then automatically generate and send an invitation link via email for them to start the process.'
                    )}
                  </p>
                  <p>{t("It's simple, safe, and secure!")}</p>
                </div>

                <div className="flex flex-row justify-end">
                  <Button onClick={handleGoToNextStep}>{t('Next')}</Button>
                </div>
              </Fragment>
            )}

            {step === 2 && (
              <coBuyerForm.Provider>
                <form
                  className="flex flex-col space-y-4 md:space-y-6"
                  onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    void coBuyerForm.handleSubmit();
                  }}
                >
                  <div className="grid grid-cols-2 gap-8 items-end">
                    <coBuyerForm.Field name="firstName">
                      {(field) => {
                        return (
                          <TextInput
                            fieldName={field.name}
                            value={field.state.value}
                            labelText={t('First Name')}
                            placeholder={t('First Name')}
                            subtitleText={t('First Name')}
                            required
                            disabled={isSubmitting}
                            error={field.state.meta.touchedErrors.at(0)}
                            onChange={(e) => {
                              field.handleChange(e.target.value);
                            }}
                          />
                        );
                      }}
                    </coBuyerForm.Field>

                    <coBuyerForm.Field name="lastName">
                      {(field) => {
                        return (
                          <TextInput
                            fieldName={field.name}
                            value={field.state.value}
                            labelText={t('Last Name')}
                            placeholder={t('Last Name')}
                            subtitleText={t('Last Name')}
                            required
                            disabled={isSubmitting}
                            error={field.state.meta.touchedErrors.at(0)}
                            onChange={(e) => {
                              field.handleChange(e.target.value);
                            }}
                          />
                        );
                      }}
                    </coBuyerForm.Field>

                    <coBuyerForm.Field name="email">
                      {(field) => {
                        return (
                          <TextInput
                            fieldName={field.name}
                            value={field.state.value}
                            isEmail
                            labelText={t('Email')}
                            placeholder={t('Email')}
                            subtitleText={t('Email')}
                            required
                            disabled={isSubmitting}
                            error={field.state.meta.touchedErrors.at(0)}
                            onChange={(e) => {
                              field.handleChange(e.target.value);
                            }}
                          />
                        );
                      }}
                    </coBuyerForm.Field>

                    <coBuyerForm.Field name="phoneNumber">
                      {(field) => {
                        return (
                          <PhoneNumberInput
                            fieldName={field.name}
                            value={field.state.value}
                            placeholder={t('Phone Number')}
                            subtitleText={t('Phone Number')}
                            onChange={(value) => {
                              field.handleChange(value?.toString() ?? '');
                            }}
                            onBlur={field.handleBlur}
                            disabled={isSubmitting}
                            required
                          />
                        );
                      }}
                    </coBuyerForm.Field>
                  </div>

                  {error && (
                    <div className="py-2 px-4 rounded bg-negative-secondary text-center text-negative">
                      {error}
                    </div>
                  )}
                </form>

                <div className="mt-8 flex flex-col">
                  <p>
                    <Trans t={t}>
                      By clicking{' '}
                      <span className="font-bold italic">"Next"</span> you are
                      authorizing the dealership to email your Co-Buyer (invited
                      party) on your behalf. The email will contain your full
                      name and the dealership you are engaged with.
                    </Trans>
                  </p>
                  <p className="mt-2">
                    <Trans t={t}>
                      <span className="font-bold">Please Note:</span> For
                      Security Purposes, all the Buyer & Co Buyer information is
                      kept separately.
                    </Trans>
                  </p>
                </div>

                <div className="pt-8 flex flex-row justify-between">
                  <Button
                    variant="TERTIARY"
                    disabled={isSubmitting}
                    onClick={() => {
                      setStep(1);
                    }}
                  >
                    {t('Back')}
                  </Button>

                  <coBuyerForm.Subscribe
                    selector={(state) => [state.values]}
                    children={([values]) => {
                      const canContinue =
                        !!values.firstName &&
                        !!values.lastName &&
                        !!values.email &&
                        !!values.phoneNumber;

                      return (
                        <Button
                          loading={isSubmitting}
                          disabled={!canContinue}
                          onClick={() => void coBuyerForm.handleSubmit()}
                        >
                          {t('Next')}
                        </Button>
                      );
                    }}
                  />
                </div>
              </coBuyerForm.Provider>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CoBuyerPage;
