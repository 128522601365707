import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@thedealersconcierge/components';
import { FC, Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import createAddressAction from '~/actions/addresses/createAddressAction';
import deleteAddressAction from '~/actions/addresses/deleteAddressAction';
import updateAddressAction from '~/actions/addresses/updateAddressAction';
import Header from '~/components/Header';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DropDown from '~/components/inputs/DropDown';
import NumberInput, {
  parseNumberInputValue
} from '~/components/inputs/NumberInput';
import TextInput from '~/components/inputs/TextInput';
import Spinner from '~/components/Spinner';
import housingStatusOptions from '~/config/formSelectionOptions/housingStatusOptions';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import customerQuery, { resetCustomerQuery } from '~/queries/customerQuery';
import { resetHardCreditQuery } from '~/queries/hardCreditQuery';
import meQuery, { resetMeQuery } from '~/queries/meQuery';
import { AddressType } from '~/querySelectors/address';
import { Link, useNavigate, useParams } from '~/router';

const HardCreditApplicationAddressInfoForm: FC<{
  userId: string;
  addresses?: AddressType[];
  isDeleting: boolean;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  userId,
  isDeleting,
  setIsDeleting,
  isSubmitting,
  setIsSubmitting,
  addresses
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { transactionId, hardCreditApplicationId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/addressInfo'
  );
  const [currentlyEditedAddress, setCurrentlyEditedAddress] =
    useState<AddressType | null>(null);

  // We are setting this in the useEffect because when navigating back and forth between the HCA steps, the addresses might be undefined on initial render
  useEffect(() => {
    const currentAddress = addresses?.find((a) => a.timelinePosition === 0);

    // The residential address set during the onboarding might not contain the housing status. In that case, we are going to edit it.
    if (currentAddress && !currentAddress.housingStatus) {
      setCurrentlyEditedAddress(currentAddress);
    }
  }, [addresses]);

  const moreThanTwoYears =
    (addresses
      ?.map((a) => (a.durationYears ?? 0) + (a.durationMonths ?? 0) / 12)
      .reduce((total, duration) => duration + total, 0) ?? 0) >= 2;

  const handleContinue = () => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/employmentInfo',
      {
        params: {
          dealershipSlug,
          transactionId,
          hardCreditApplicationId
        }
      }
    );
  };

  const form = useForm({
    defaultValues: {
      street: currentlyEditedAddress?.street ?? '',
      apartmentDetails: currentlyEditedAddress?.apartmentDetails ?? '',
      zipCode: currentlyEditedAddress?.zipCode ?? '',
      city: currentlyEditedAddress?.city ?? '',
      state: currentlyEditedAddress?.state?.toLocaleLowerCase() ?? '',
      housingStatus: currentlyEditedAddress?.housingStatus ?? '',
      monthlyPayment: currentlyEditedAddress?.monthlyPayment?.toString() ?? '',
      durationYears: currentlyEditedAddress?.durationYears?.toString() ?? '',
      durationMonths: currentlyEditedAddress?.durationMonths?.toString() ?? ''
    },
    onSubmit: async (values, form) => {
      setIsSubmitting(true);

      if (currentlyEditedAddress) {
        await updateAddressAction(
          currentlyEditedAddress.id ?? 'no-address-id',
          {
            ...values,
            monthlyPayment: parseNumberInputValue(values.monthlyPayment),
            durationYears: parseNumberInputValue(values.durationYears),
            durationMonths: parseNumberInputValue(values.durationMonths) ?? 0
          }
        );
      } else {
        await createAddressAction(
          {
            ...values,
            monthlyPayment: parseNumberInputValue(values.monthlyPayment),
            durationYears: parseNumberInputValue(values.durationYears),
            durationMonths: parseNumberInputValue(values.durationMonths) ?? 0,
            timelinePosition: addresses?.length
          },
          transactionId
        );
      }

      await Promise.all([
        resetMeQuery(),
        resetCustomerQuery(transactionId, userId),
        resetHardCreditQuery(transactionId)
      ]);

      setCurrentlyEditedAddress(null);
      form.reset();
      setIsSubmitting(false);
    }
  });
  const handleEdit = (address: AddressType) => {
    form.setFieldValue('street', address.street ?? '');
    form.setFieldValue('apartmentDetails', address.apartmentDetails ?? '');
    form.setFieldValue('zipCode', address.zipCode ?? '');
    form.setFieldValue('city', address.city ?? '');
    form.setFieldValue('state', address.state ?? '');
    form.setFieldValue('housingStatus', address.housingStatus ?? '');
    form.setFieldValue(
      'monthlyPayment',
      address.monthlyPayment?.toString() ?? ''
    );
    form.setFieldValue(
      'durationYears',
      address.durationYears?.toString() ?? ''
    );
    form.setFieldValue(
      'durationMonths',
      address.durationMonths?.toString() ?? ''
    );

    setCurrentlyEditedAddress(address);
  };
  const handleDelete = async (addressId: string) => {
    setIsDeleting(true);

    await deleteAddressAction(addressId);
    await Promise.all([
      resetMeQuery(),
      resetCustomerQuery(transactionId, userId),
      resetHardCreditQuery(transactionId)
    ]);

    setCurrentlyEditedAddress(null);
    form.reset();
    setIsDeleting(false);
  };

  const renderAddress = (address: AddressType) => {
    // Check whether the address information has been filled out completely.
    // During the onboarding, a residential address is given in some cases.
    // But in the onboarding, the user isn't asked about the housing status.
    // Here, we only want to render addresses with all the data.
    // Since housing status is part of the required fields, we can check based
    // on that field and don't render the address if it doesn't have a value for it.
    if (!address.housingStatus) {
      return undefined;
    }

    return (
      <div
        key={address.id}
        className="flex flex-col space-y-4 w-full rounded-lg overflow-hidden shadow-border p-4"
      >
        <div className="flex flex-col-reverse md:flex-row md:justify-between gap-2">
          <h2>
            {(address.timelinePosition ?? 0) === 0 &&
              t('Current Employment Information')}
            {(address.timelinePosition ?? 0) > 0 &&
              t('previousAddressAndHousingStatus', {
                count: address.timelinePosition ?? 0,
                ordinal: true
              })}
          </h2>

          <div className="flex flex-row items-center justify-end">
            <Button
              label={t('Edit')}
              size="SMALL"
              variant="GHOST"
              onClick={() => {
                handleEdit(address);
              }}
              disabled={isSubmitting || isDeleting}
            />

            <span className="icon-tertiary text-sm">|</span>

            <Button
              label={t('Delete')}
              size="SMALL"
              variant="GHOST"
              disabled={(addresses?.length ?? 0) < 2 || isSubmitting}
              onClick={() => {
                void handleDelete(address.id ?? 'no-address-id');
              }}
              isLoading={isDeleting}
            />
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
          <div className="space-y-2 col-span-2 md:col-span-1">
            <p className="text-body-2 text-tertiary">{t('Address:')}</p>

            <p className="text-secondary">{address.fullAddress}</p>
          </div>

          <div className="space-y-2">
            <p className="text-body-2 text-tertiary">{t('Time at Address:')}</p>

            <p className="text-secondary">
              {t('{{years}} Yrs {{months}} Mo', {
                years: address.durationYears,
                months: address.durationMonths
              })}
            </p>
          </div>

          <div className="space-y-2">
            <p className="text-body-2 text-tertiary">{t('Housing Status:')}</p>

            <p className="text-secondary">
              {
                housingStatusOptions(t).find(
                  (o) => o.value === address.housingStatus
                )?.label
              }
            </p>
          </div>

          <div className="space-y-2">
            <p className="text-body-2 text-tertiary">{t('Monthly Payment:')}</p>
            <p className="text-secondary">
              {t('${{amount}}/month', {
                amount: address.monthlyPayment?.toLocaleString()
              })}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-col space-y-8 md:space-y-16 w-full items-center">
      {(!moreThanTwoYears || currentlyEditedAddress) && (
        <div className="flex flex-col w-full space-y-4 bg-secondary border-y border-secondary items-center py-6 px-6">
          <div className="flex flex-row justify-between w-full max-w-screen-md items-center">
            <h2>
              {(currentlyEditedAddress?.timelinePosition === 0 ||
                (!currentlyEditedAddress && addresses?.length === 0)) &&
                t('Address Information and Housing Status')}
              {(currentlyEditedAddress?.timelinePosition === 1 ||
                (!currentlyEditedAddress && addresses?.length === 1)) &&
                t('Previous Address Information and Housing Status')}
              {(currentlyEditedAddress?.timelinePosition === 2 ||
                (!currentlyEditedAddress && addresses?.length === 2)) &&
                t('2nd Previous Address Information and Housing Status')}
              {(currentlyEditedAddress?.timelinePosition === 3 ||
                (!currentlyEditedAddress && addresses?.length === 3)) &&
                t('3rd Previous Address Information and Housing Status')}
              {((currentlyEditedAddress?.timelinePosition !== undefined &&
                currentlyEditedAddress.timelinePosition > 3) ||
                (!currentlyEditedAddress &&
                  addresses?.length !== undefined &&
                  addresses.length > 3)) &&
                `${currentlyEditedAddress?.timelinePosition ?? addresses?.length}th Previous Address Information and Housing Status`}
            </h2>
          </div>

          <form.Provider>
            <form
              className="grid grid-cols-2 md:grid-cols-8 gap-4 w-full max-w-screen-md md:items-end"
              onSubmit={(e) => {
                e.preventDefault();
                e.stopPropagation();
                void form.handleSubmit();
              }}
            >
              <form.Field name="street">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText={t('Street')}
                      subtitleText={t('Street')}
                      placeholder={t('Street')}
                      required
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                      containerClassName="md:col-span-4"
                      dataTestId="hca-step2-street-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="apartmentDetails">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText={t('Apartment Details')}
                      subtitleText={t('Apartment Details')}
                      placeholder={t('Suite, Apt #')}
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                      containerClassName="md:col-span-4"
                      dataTestId="hca-step2-apartment-details-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="zipCode">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText={t('Zip Code')}
                      subtitleText={t('Zip Code')}
                      placeholder={t('Zip Code')}
                      required
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                      containerClassName="md:col-span-3"
                      dataTestId="hca-step2-zipcode-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="city">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText={t('City')}
                      subtitleText={t('City')}
                      placeholder={t('City')}
                      required
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                      containerClassName="md:col-span-2"
                      dataTestId="hca-step2-city-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="state">
                {(field) => {
                  return (
                    <DropDown
                      fieldName={field.name}
                      value={field.state.value}
                      options={stateOptions}
                      labelText={t('State')}
                      placeholder={t('State')}
                      subtitleText={t('State')}
                      required
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                      containerClassName="col-span-2  md:col-span-3"
                      dataTestId="hca-step2-state-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="housingStatus">
                {(field) => {
                  return (
                    <DropDown
                      fieldName={field.name}
                      value={field.state.value}
                      options={housingStatusOptions(t)}
                      labelText={t('Housing Status')}
                      subtitleText={t('Housing Status')}
                      placeholder={t('Housing Status')}
                      required
                      disabled={isSubmitting || isDeleting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                      containerClassName="md:col-span-4"
                      dataTestId="hca-step2-housing-status-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="monthlyPayment">
                {(field) => {
                  return (
                    <NumberInput
                      fieldName={field.name}
                      value={field.state.value}
                      subtitleText={t('Monthly Payment:')}
                      prefix="$"
                      suffix={t('/month')}
                      placeholder={t('/month')}
                      required
                      disabled={isSubmitting || isDeleting}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                      containerClassName="md:col-span-4"
                      dataTestId="hca-step2-monthly-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="durationYears">
                {(field) => {
                  return (
                    <NumberInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText={t('Duration in Years')}
                      subtitleText={t(
                        'How many years have you been living at this address?'
                      )}
                      placeholder={t('Duration (Yrs.)')}
                      disabled={isSubmitting || isDeleting}
                      required
                      suffix={t(' Yrs.')}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                      containerClassName="md:col-span-4"
                      dataTestId="hca-step2-duration-year-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="durationMonths">
                {(field) => {
                  return (
                    <NumberInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText={t('Duration in Months')}
                      subtitleText={t(
                        'How many months have you been living at this address?'
                      )}
                      placeholder={t('Duration (Mos.)')}
                      suffix={t(' Mos.')}
                      disabled={isSubmitting || isDeleting}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                      containerClassName="md:col-span-4 h-full"
                      dataTestId="hca-step2-duration-months-input"
                    />
                  );
                }}
              </form.Field>

              <div className="flex flex-row justify-end col-span-2 md:col-span-8">
                <form.Subscribe
                  selector={(state) => [state.values]}
                  children={([values]) => {
                    const canContinue =
                      !!values.street &&
                      !!values.zipCode &&
                      !!values.city &&
                      !!values.state &&
                      !!values.housingStatus &&
                      !!values.monthlyPayment &&
                      values.durationYears !== '' &&
                      (parseInt(values.durationYears.toString()) > 0 ||
                        parseInt(values.durationMonths.toString()) > 0);

                    return (
                      <Button
                        label={t('Save')}
                        disabled={!canContinue || isDeleting}
                        isLoading={isSubmitting}
                        type="submit"
                        dataTestId="hca-step2-save-button"
                      />
                    );
                  }}
                />
              </div>
            </form>
          </form.Provider>
        </div>
      )}

      <div className="flex w-full px-6 justify-center">
        <div className="flex flex-col space-y-6 md:space-y-12 w-full max-w-screen-md bg-primary">
          {addresses?.map(renderAddress)}
        </div>
      </div>

      <div className="flex w-full px-6 justify-center">
        <div className="flex flex-row justify-end w-full max-w-screen-md">
          <Button
            label={t('Next')}
            onClick={handleContinue}
            disabled={isSubmitting || !moreThanTwoYears || isDeleting}
            dataTestId="hca-step2-next-button"
          />
        </div>
      </div>
    </div>
  );
};

const HardCreditApplicationAddressInfoPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { transactionId, hardCreditApplicationId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/addressInfo'
  );
  const { data: meData } = useQuery(meQuery());
  const userId = meData?.me?.user?.id ?? 'no-user-id';
  const { data: customer, isFetched } = useQuery(
    customerQuery(transactionId, userId, dealershipSlug)
  );
  const [isDeleting, setIsDeleting] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const addresses = customer?.customer?.residentialAddresses;

  return (
    <div className="flex flex-col h-dvh" data-test-id={'hca-step-2-body'}>
      <Header
        title={t('Hard Credit Application')}
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting || isDeleting}
          >
            {t('Cancel')}
          </button>
        }
        totalSteps={5}
        currentStep={2}
      />

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center pt-4">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/basicInfo"
            params={{ transactionId, hardCreditApplicationId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col py-10 space-y-6 w-full items-center overflow-y-scroll h-full">
        {isFetched ? (
          <Fragment>
            <div className="flex w-full px-6 justify-center">
              <div className="flex flex-col space-y-4 w-full max-w-screen-md bg-primary">
                <h2>{t('Credit Application')}</h2>

                <p>
                  {t(
                    'Including the last two years of your housing is required.'
                  )}
                </p>
              </div>
            </div>

            <HardCreditApplicationAddressInfoForm
              userId={userId}
              addresses={addresses?.edges?.map((e) => e.node ?? {})}
              isDeleting={isDeleting}
              setIsDeleting={setIsDeleting}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
            />
          </Fragment>
        ) : (
          <div
            className="flex w-full aspect-square justify-center items-center relative"
            data-test-id={'hca-step2-spinner'}
          >
            <Spinner className="w-10 aspect-square" />
          </div>
        )}
      </div>
    </div>
  );
};

export default HardCreditApplicationAddressInfoPage;
