import classNames from 'classnames';
import { ButtonHTMLAttributes, cloneElement, FC, Fragment } from 'react';
import { twMerge } from 'tailwind-merge';
import Spinner from '../spinner';
import { ButtonSize, ButtonVariant } from './buttonConfig';

/**
 * @param icon: Should be an imported component from '@mui/icons-material'
 */
const Button: FC<
  {
    label?: string;
    icon?: JSX.Element;
    variant?: ButtonVariant;
    size?: ButtonSize;
    isLoading?: boolean;
    className?: string;
    dataTestId?: string;
  } & ButtonHTMLAttributes<HTMLButtonElement>
> = ({
  label,
  icon,
  variant = 'PRIMARY',
  size = 'LARGE',
  isLoading,
  className,
  dataTestId,
  ...props
}) => {
  const iconOnly = icon && !label;

  return (
    <button
      className={twMerge(
        classNames(
          'w-fit rounded-radius-02 disabled:opacity-40 disabled:cursor-not-allowed',
          {
            'px-spacing-05 py-spacing-03': size === 'LARGE' && !iconOnly,
            'px-spacing-04 py-spacing-02': size === 'MEDIUM' && !iconOnly,
            'px-spacing-03 py-spacing-02': size === 'SMALL' && !iconOnly,
            'px-spacing-02 py-spacing-01': size === 'XSMALL' && !iconOnly,
            'p-spacing-03': size === 'LARGE' && iconOnly,
            'p-spacing-02': ['MEDIUM', 'SMALL'].includes(size) && iconOnly,
            'p-spacing-01': size === 'XSMALL' && iconOnly,
            'h-12 w-[88px]': size === 'LARGE' && isLoading, // The order here is important to overwrite preceeding sizes
            'h-10 w-[72px]': size === 'MEDIUM' && isLoading, // The order here is important to overwrite preceeding sizes
            'h-8 w-16': size === 'SMALL' && isLoading, // The order here is important to overwrite preceeding sizes
            'h-6 w-14': size === 'XSMALL' && isLoading, // The order here is important to overwrite preceeding sizes
            'text-inverse bg-blue-600 focus:ring-2 focus:ring-blue-800 focus:ring-offset-2':
              variant === 'PRIMARY',
            'text-interactive': variant === 'GHOST',
            'text-interactive outline outline-2 -outline-offset-2 outline-blue-600 focus:outline-none focus:ring focus:ring-blue-800 focus:ring-offset-2':
              variant === 'SECONDARY',
            'hover:bg-blue-700 active:bg-blue-800':
              variant === 'PRIMARY' && !isLoading,
            'hover:outline-blue-700 active:outline-blue-800 hover:text-blue-700 active:text-blue-800':
              variant === 'SECONDARY' && !isLoading,
            'hover:bg-gray-100 active:bg-gray-200 focus:ring focus:ring-blue-800 focus:ring-offset-2':
              variant === 'GHOST' && !isLoading
          }
        ),
        className
      )}
      data-test-id={dataTestId}
      disabled={props.disabled || isLoading}
      {...props}
    >
      <span className="flex flex-row items-center justify-center">
        {!isLoading && (
          <Fragment>
            {icon && (
              <span className="flex h-fit">
                {cloneElement(icon, {
                  sx: { fontSize: ['MEDIUM', 'LARGE'].includes(size) ? 20 : 16 }
                })}
              </span>
            )}

            {label && (
              <span
                className={classNames('whitespace-nowrap', {
                  'label-01 px-spacing-01 py-[2px]': [
                    'MEDIUM',
                    'LARGE'
                  ].includes(size),
                  'label-02 font-normal px-[2px]': ['SMALL', 'XSMALL'].includes(
                    size
                  )
                })}
              >
                {label}
              </span>
            )}
          </Fragment>
        )}

        {isLoading && (
          <Spinner
            size={['MEDIUM', 'LARGE'].includes(size) ? 'SMALL' : 'XSMALL'}
            color="BLUE"
          />
        )}
      </span>
    </button>
  );
};

export default Button;
