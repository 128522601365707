import { FC } from 'react';

const IdIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 24 24"
    >
      <title>ID icon</title>

      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2.25H6V3C6 3.82854 6.67146 4.5 7.5 4.5C8.32854 4.5 9 3.82854 9 3V2.25H15V3C15 3.82854 15.6715 4.5 16.5 4.5C17.3285 4.5 18 3.82854 18 3V2.25H24V21.75H0V2.25ZM1.5 3.75V20.25H22.5V3.75H19.4055C19.0725 5.04398 17.898 6 16.5 6C15.102 6 13.9275 5.04398 13.5945 3.75H10.4055C10.0725 5.04398 8.89799 6 7.5 6C6.10202 6 4.9275 5.04398 4.5945 3.75H1.5ZM7.5 9.75C7.08579 9.75 6.75 10.0858 6.75 10.5C6.75 10.9142 7.08579 11.25 7.5 11.25C7.91421 11.25 8.25 10.9142 8.25 10.5C8.25 10.0858 7.91422 9.75 7.5 9.75ZM5.25 10.5C5.25 9.25736 6.25736 8.25 7.5 8.25C8.74264 8.25 9.75 9.25736 9.75 10.5C9.75 11.7426 8.74264 12.75 7.5 12.75C6.25736 12.75 5.25 11.7426 5.25 10.5ZM13.5 10.5H20.25V12H13.5V10.5ZM4.62806 16.5H10.3719C10.063 15.6262 9.22957 15 8.25 15H6.75C5.77043 15 4.93697 15.6262 4.62806 16.5ZM3 17.25C3 15.179 4.67904 13.5 6.75 13.5H8.25C10.321 13.5 12 15.179 12 17.25V18H3V17.25ZM13.5 14.25H20.25V15.75H13.5V14.25Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default IdIcon;
