import * as Sentry from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { socialSecurityNumber } from '@thedealersconcierge/lib/codecs/validation/socialSecurityNumber';
import classNames from 'classnames';
import { FC, Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import submitPreQualApplicationAction from '~/actions/formSubmissions/prequal/submitPreQualApplicationAction';
import Button from '~/components/Button';
import Signature from '~/components/Signature';
import Checkbox from '~/components/inputs/Checkbox';
import DateInput from '~/components/inputs/DateInput';
import DropDown from '~/components/inputs/DropDown';
import SocialSecurityNumberInput from '~/components/inputs/SocialSecurityNumberInput';
import TextInput from '~/components/inputs/TextInput';
import config from '~/config';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import { getDateFromUnkown } from '~/lib/utils';
import customerQuery from '~/queries/customerQuery';
import dealershipQuery from '~/queries/dealershipQuery';
import { MeQueryType } from '~/queries/meQuery';
import { TransactionQueryType } from '~/queries/transactionQuery';

const PreQualForm: FC<{
  transactionId: string;
  isSubmitting: boolean;
  meData?: MeQueryType;
  transactionData?: TransactionQueryType;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccess: () => void;
  dealershipSlug: string;
}> = ({
  transactionId,
  isSubmitting,
  meData,
  transactionData,
  setIsSubmitting,
  onSuccess,
  dealershipSlug
}) => {
  const { t, i18n } = useTranslation();
  const [error, setError] = useState<string | undefined>(undefined);
  const [signature, setSignature] = useState<string | null>(null);
  const { data: customerData } = useQuery(
    customerQuery(transactionId, meData?.me?.user?.id, dealershipSlug)
  );
  const cust = customerData?.customer;

  const residentialAddress = cust?.residentialAddresses?.edges?.find(
    (e) => e.node?.timelinePosition === 0
  )?.node;
  const user = meData?.me?.user;

  const { data: dealershipData } = useQuery(
    dealershipQuery({ id: transactionData?.transaction?.dealership?.id })
  );
  const form = useForm({
    defaultValues: {
      firstName: cust?.firstName ?? user?.firstName ?? '',
      middleName: cust?.middleName ?? '',
      lastName: cust?.lastName ?? user?.lastName ?? '',
      birthdate: getDateFromUnkown(cust?.birthdate) ?? null,
      socialSecurityNumber: cust?.socialSecurityNumber ?? '',
      addressStreet: residentialAddress?.street ?? '',
      addressApartmentDetails: residentialAddress?.apartmentDetails ?? '',
      addressZipCode: residentialAddress?.zipCode ?? '',
      addressCity: residentialAddress?.city ?? '',
      addressState: residentialAddress?.state?.toLocaleLowerCase() ?? '',
      acknowledgesInformationUnderstanding: false,
      understandsCreditScoreAffection: false,
      acknowledgesPrivacyNotice: false,
      providedInstructions: false
    },
    onSubmit: async (values) => {
      try {
        // Mostly for types, as the form is deactivated when not signed.
        if (!signature) {
          alert(t('Please sign'));
          return;
        }

        // This is for typing. Submit is disabled if birthdate is not set
        if (!values.birthdate) {
          toast.error(t('Birthdate is missing'));
          return;
        }

        setError(undefined);
        setIsSubmitting(true);

        await submitPreQualApplicationAction(
          t,
          { ...values, birthdate: values.birthdate }, // Have to use the spread operator for the type system to pick up that we checked that the birthdate is defined
          dealershipData?.dealership?.name ?? 'No name',
          meData?.me?.ipAddress ?? 'Unknown IP',
          signature,
          transactionId
        );

        onSuccess();

        // Can't seem to find better ways to do this
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        Sentry.captureException(error);
        console.error(error);
        setError(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  const dealership = dealershipData?.dealership;

  return (
    <form.Provider>
      <form
        className="flex flex-col w-full"
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
      >
        <div className="flex w-full px-6 pb-6 justify-center">
          <div className="flex flex-col items-start w-full max-w-screen-md">
            <form.Field
              name="acknowledgesInformationUnderstanding"
              children={(field) => {
                return (
                  <div
                    onClick={() => {
                      field.handleChange(!field.state.value);
                    }}
                    className="flex flex-row space-x-2 items-center cursor-pointer"
                  >
                    <Checkbox
                      inputId={field.name}
                      value={field.state.value}
                      onChange={() => {
                        field.handleChange(!field.state.value);
                      }}
                      disabled={isSubmitting}
                      dataTestId="prequal-app-ackbnowlage-checkbox"
                    />
                    <p className="text-secondary">
                      {t('I acknowledge and agree.')}
                    </p>
                  </div>
                );
              }}
            />
          </div>
        </div>

        <form.Subscribe>
          {(f) => {
            const values = f.values;
            const canContinue =
              f.isValid &&
              !!values.firstName &&
              !!values.lastName &&
              !!values.birthdate &&
              !!values.socialSecurityNumber &&
              !!values.addressStreet &&
              !!values.addressZipCode &&
              !!values.addressCity &&
              !!values.addressState &&
              !!values.acknowledgesPrivacyNotice &&
              !!values.understandsCreditScoreAffection &&
              !!values.providedInstructions &&
              signature;

            return (
              <Fragment>
                <div className="flex w-full border-y border-secondary bg-secondary p-6 justify-center">
                  <div className="flex flex-col items-start w-full max-w-screen-md space-y-6">
                    <h2
                      className={classNames({
                        'text-inactive':
                          !values.acknowledgesInformationUnderstanding
                      })}
                    >
                      {t('Basic Information')}
                    </h2>

                    <div className="grid grid-cols-2 md:grid-cols-8 gap-4 md:items-end w-full">
                      <form.Field name="firstName">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('First Name')}
                              placeholder={t('First Name')}
                              subtitleText={t('First Name')}
                              required
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-3"
                              dataTestId="prequal-app-firstaname-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="middleName">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('Middle Name')}
                              subtitleText={t('Middle Name')}
                              placeholder={t('Middle Name')}
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-2"
                              dataTestId="prequal-app-middlename-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="lastName">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('Last Name')}
                              placeholder={t('Last Name')}
                              subtitleText={t('Last Name')}
                              required
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-3"
                              dataTestId="prequal-app-lastname-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="birthdate">
                        {(field) => {
                          return (
                            <DateInput
                              fieldName={field.name}
                              value={field.state.value}
                              subtitleText={t('Your Date of Birth')}
                              placeholderText={t('Birthdate')}
                              required
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(updatedDate) => {
                                field.handleChange(updatedDate);
                              }}
                              containerClassName="md:col-span-4"
                              dataTestId="prequal-app-birthdate-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field
                        name="socialSecurityNumber"
                        onBlur={(ssn) => {
                          if (!socialSecurityNumber().safeParse(ssn).success) {
                            return t('Invalid social security number');
                          }

                          return undefined;
                        }}
                      >
                        {(field) => {
                          return (
                            <SocialSecurityNumberInput
                              value={field.state.value}
                              fieldName={field.name}
                              subtitleText={t('Social Security Number')}
                              placeholder={t('Social Security Number')}
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              required
                              onBlur={field.handleBlur}
                              error={field.state.meta.touchedErrors
                                .at(0)
                                ?.toString()}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              setFieldValue={(_fieldName, value) => {
                                form.setFieldValue(field.name, value);
                              }}
                              containerClassName="col-span-2 md:col-span-4"
                              dataTestId="prequal-app-ssn-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="addressStreet">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('Street Address')}
                              placeholder={t('Street Address')}
                              subtitleText={t('Street Address')}
                              required
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="col-span-2 md:col-span-4"
                              dataTestId="prequal-app-addressStreet-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="addressApartmentDetails">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('Apartment Details')}
                              placeholder={t('Suite, Apartment #')}
                              subtitleText={t('Apartment Details')}
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-4"
                              dataTestId="prequal-app-addressApartmentDetails-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="addressZipCode">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('ZIP Code')}
                              placeholder={t('ZIP Code')}
                              subtitleText={t('ZIP Code')}
                              required
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-3"
                              dataTestId="prequal-app-addressZipCode-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="addressCity">
                        {(field) => {
                          return (
                            <TextInput
                              fieldName={field.name}
                              value={field.state.value}
                              labelText={t('City')}
                              placeholder={t('City')}
                              subtitleText={t('City')}
                              required
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-2"
                              dataTestId="prequal-app-addressCity-input"
                            />
                          );
                        }}
                      </form.Field>

                      <form.Field name="addressState">
                        {(field) => {
                          return (
                            <DropDown
                              fieldName={field.name}
                              value={field.state.value}
                              options={stateOptions}
                              labelText={t('State')}
                              placeholder={t('State')}
                              subtitleText={t('State')}
                              required
                              disabled={
                                isSubmitting ||
                                !values.acknowledgesInformationUnderstanding
                              }
                              error={field.state.meta.touchedErrors.at(0)}
                              onChange={(e) => {
                                field.handleChange(e.target.value);
                              }}
                              containerClassName="md:col-span-3"
                              dataTestId="prequal-app-addressState-input"
                            />
                          );
                        }}
                      </form.Field>
                    </div>
                  </div>
                </div>

                <div className="flex w-full bg-primary p-6 justify-center">
                  <div className="flex flex-col items-start w-full max-w-screen-md space-y-6">
                    <div className="space-y-4">
                      <h2
                        className={classNames({
                          'text-inactive':
                            !values.acknowledgesInformationUnderstanding
                        })}
                      >
                        {t('Our Commitment to Privacy')}
                      </h2>

                      <p
                        className={classNames({
                          'text-inactive':
                            !values.acknowledgesInformationUnderstanding
                        })}
                      >
                        {t(
                          'All information stored in our database is secure and is strictly confidential. Your personal and credit information will only be used to fulfill your request and in accordance with our Privacy Policy.'
                        )}
                      </p>

                      <form.Field
                        name="understandsCreditScoreAffection"
                        children={(field) => {
                          return (
                            <div
                              className="flex flex-row space-x-4 cursor-pointer"
                              onClick={() => {
                                field.handleChange(!field.state.value);
                              }}
                            >
                              <Checkbox
                                inputId={field.name}
                                value={field.state.value}
                                onChange={() => {
                                  field.handleChange(!field.state.value);
                                }}
                                disabled={
                                  isSubmitting ||
                                  !values.acknowledgesInformationUnderstanding
                                }
                                containerClassName="pt-1"
                                dataTestId="prequal-app-i-understand-checkbox"
                              />
                              <p
                                className={classNames({
                                  'text-inactive':
                                    !values.acknowledgesInformationUnderstanding
                                })}
                              >
                                {t(
                                  'I understand that this is not an application for credit, and submission will NOT affect credit score.'
                                )}
                              </p>
                            </div>
                          );
                        }}
                      />

                      <form.Field
                        name="acknowledgesPrivacyNotice"
                        children={(field) => {
                          return (
                            <div
                              className="flex flex-row space-x-4 cursor-pointer"
                              onClick={() => {
                                field.handleChange(!field.state.value);
                              }}
                            >
                              <Checkbox
                                inputId={field.name}
                                value={field.state.value}
                                onChange={() => {
                                  field.handleChange(!field.state.value);
                                }}
                                disabled={
                                  isSubmitting ||
                                  !values.acknowledgesInformationUnderstanding
                                }
                                containerClassName="pt-1"
                                dataTestId="prequal-app-privacy-notice-checkbox"
                              />

                              <p
                                className={classNames({
                                  'text-inactive':
                                    !values.acknowledgesInformationUnderstanding
                                })}
                              >
                                <Trans
                                  t={t}
                                  values={{
                                    dealershipName: dealership?.name ?? '...'
                                  }}
                                >
                                  I hereby provide my consent to have my credit
                                  file accessed for purposes of prequalifying
                                  for a vehicle loan. This is a soft inquiry and
                                  will not impact my credit score. I agree to
                                  the{' '}
                                  <a
                                    href={t('privacy-notice', {
                                      backendUrl: config.rawBackendUrl,
                                      dealershipSlug: dealershipSlug,
                                      lang: i18n.language,

                                      ns: 'files',
                                      defaultValue:
                                        '{{backendUrl}}/document/{{dealershipSlug}}/{{lang}}/privacy-notice.pdf'
                                    })}
                                    target="_blank"
                                    className={classNames({
                                      'text-primary-brand cursor-pointer  hover:underline':
                                        values.acknowledgesInformationUnderstanding
                                    })}
                                  >
                                    Privacy Notice
                                  </a>
                                  ,{' '}
                                  <a
                                    href={t('prequal-terms-and-conditions', {
                                      ns: 'files',
                                      defaultValue:
                                        'https://files.mytdc.net/prequal-terms-and-conditions.pdf'
                                    })}
                                    target="_blank"
                                    className={classNames({
                                      'text-primary-brand cursor-pointer hover:underline':
                                        values.acknowledgesInformationUnderstanding
                                    })}
                                  >
                                    Terms and Conditions
                                  </a>{' '}
                                  and I acknowledge I may be contacted by{' '}
                                  {'{{dealershipName}}'}. I understand that I
                                  might not prequalify depending on the
                                  prequalification criteria.
                                </Trans>
                              </p>
                            </div>
                          );
                        }}
                      />

                      <form.Field
                        name="providedInstructions"
                        children={(field) => {
                          return (
                            <div
                              className="flex flex-row space-x-4 cursor-pointer"
                              onClick={() => {
                                field.handleChange(!field.state.value);
                              }}
                            >
                              <Checkbox
                                inputId={field.name}
                                value={field.state.value}
                                onChange={() => {
                                  field.handleChange(!field.state.value);
                                }}
                                disabled={
                                  isSubmitting ||
                                  !values.acknowledgesInformationUnderstanding
                                }
                                containerClassName="pt-1"
                                dataTestId="prequal-app-provided-instruction-checkbox"
                              />

                              <p
                                className={classNames({
                                  'text-inactive':
                                    !values.acknowledgesInformationUnderstanding
                                })}
                              >
                                {t(
                                  'I hereby provide my consent to receive all disclosures electronically via the TDC Buyer Portal. I have been duly informed regarding the procedures for accessing this portal, and I have access to my email address. Additionally, I acknowledge that I may request and receive printed versions of these disclosures.'
                                )}
                              </p>
                            </div>
                          );
                        }}
                      />
                    </div>

                    <div className="flex flex-col space-y-2 w-full">
                      <div
                        className={classNames('font-bold', {
                          'text-inactive':
                            !values.acknowledgesInformationUnderstanding
                        })}
                      >
                        {t('Signature')}
                      </div>

                      <Signature
                        dataTestIdOpenModal="prequal-app-signature-open-modal"
                        dataTestIdCanvas="prequal-app-signature-canvas"
                        dataTestIdSubmit="prequal-app-signature-submit"
                        existingSignature={signature}
                        onSignatureSubmit={(submittedSignature) => {
                          setSignature(submittedSignature);
                        }}
                        disabled={
                          isSubmitting ||
                          !values.acknowledgesInformationUnderstanding
                        }
                      />
                    </div>

                    {error && (
                      <p className="text-negative">{error.toString()}</p>
                    )}

                    <div className="flex w-full justify-end">
                      <Button
                        type="submit"
                        disabled={isSubmitting || !canContinue}
                        loading={isSubmitting}
                        dataTestId="prequal-app-submit-button"
                      >
                        {t('Submit')}
                      </Button>
                    </div>
                  </div>
                </div>
              </Fragment>
            );
          }}
        </form.Subscribe>
      </form>
    </form.Provider>
  );
};

export default PreQualForm;
