import * as Sentry from '@sentry/react';
import { useForm } from '@tanstack/react-form';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@thedealersconcierge/components';
import { socialSecurityNumber } from '@thedealersconcierge/lib/codecs/validation/socialSecurityNumber';
import { FC, Fragment, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Header from '~/components/Header';
import Spinner from '~/components/Spinner';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DateInput from '~/components/inputs/DateInput';
import DropDown from '~/components/inputs/DropDown';
import PhoneNumberInput from '~/components/inputs/PhoneNumberInput';
import SocialSecurityNumberInput from '~/components/inputs/SocialSecurityNumberInput';
import TextInput from '~/components/inputs/TextInput';
import stateOptions from '~/config/formSelectionOptions/stateOptions';
import { gqlMutationClient } from '~/lib/backend';
import { getDateFromUnkown } from '~/lib/utils';
import customerQuery, { CustomerQueryType } from '~/queries/customerQuery';
import {
  mkHardCreditQuery,
  resetHardCreditQuery
} from '~/queries/hardCreditQuery';
import meQuery, { resetMeQuery } from '~/queries/meQuery';
import { HardCreditApplicationType } from '~/querySelectors/hardCreditApplication';
import { IdCardType } from '~/querySelectors/idCard';
import { UserType } from '~/querySelectors/userSelector';
import { Link, useNavigate, useParams } from '~/router';
import { hardCreditApplicationQuery } from './_query';

const HardCreditBasicInfoForm: FC<{
  hardCreditApplication?: HardCreditApplicationType;
  customer: CustomerQueryType;
  idCard?: IdCardType;
  user?: UserType;
  isSubmitting: boolean;
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  customer,
  hardCreditApplication,
  idCard,
  user,
  isSubmitting,
  setIsSubmitting
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [error, setError] = useState<string | undefined>(undefined);
  const { transactionId, hardCreditApplicationId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/basicInfo'
  );

  const cust = customer.customer;

  const form = useForm({
    defaultValues: {
      firstName: hardCreditApplication?.firstName ?? cust?.firstName ?? '',
      lastName: hardCreditApplication?.lastName ?? cust?.lastName ?? '',
      middleName: hardCreditApplication?.middleName ?? cust?.middleName ?? '',
      birthdate:
        getDateFromUnkown(hardCreditApplication?.birthdate) ?? // Birhtdate has type unkown for some reason. This is a quick fix.
        getDateFromUnkown(cust?.birthdate) ?? // Birhtdate has type unkown for some reason. This is a quick fix.
        null,
      socialSecurityNumber:
        hardCreditApplication?.socialSecurityNumber ??
        cust?.socialSecurityNumber ??
        '',
      driversLicenseNumber:
        hardCreditApplication?.driversLicenseNumber ??
        (idCard?.type === 'DRIVERS_LICENSE' ? idCard?.idNumber : ''),
      driversLicenseState:
        hardCreditApplication?.driversLicenseState ??
        (idCard?.type === 'DRIVERS_LICENSE'
          ? (idCard.stateInAddress?.toLocaleLowerCase() ?? '')
          : ''),
      homePhoneNumber: hardCreditApplication?.homePhoneNumber ?? '',
      phoneNumber:
        hardCreditApplication?.phoneNumber ?? user?.phoneNumber ?? '',
      email: hardCreditApplication?.email ?? user?.email ?? ''
    },
    onSubmit: async (values) => {
      try {
        setIsSubmitting(true);
        setError(undefined);

        const resp = await gqlMutationClient({
          dealershipSlug
        })({
          updateHardCreditApplication: [
            {
              id: hardCreditApplicationId,
              hardCreditApplication: {
                ...values,
                homePhoneNumber: values.homePhoneNumber.length
                  ? values.homePhoneNumber
                  : undefined, // We have to pass undefined because otherwise the phone number validation check fails on an empty string
                birthdate: values.birthdate
              }
            },
            {
              __typename: true,
              '...on GraphQLError': {
                message: true
              },
              '...on MutationUpdateHardCreditApplicationSuccess': {
                data: {
                  status: true
                }
              }
            }
          ]
        });

        if (
          !resp.updateHardCreditApplication ||
          resp.updateHardCreditApplication.__typename === 'GraphQLError'
        ) {
          console.error(
            resp.updateHardCreditApplication?.message ?? 'Unexpected error'
          );
          setError(
            resp.updateHardCreditApplication?.message ?? 'Unexpected error'
          );
        } else {
          resetMeQuery();
          resetHardCreditQuery(transactionId);
          navigate(
            '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/addressInfo',
            {
              params: {
                transactionId,
                hardCreditApplicationId,
                dealershipSlug
              }
            }
          );
        }

        setIsSubmitting(false);
      } catch (e: any) {
        Sentry.captureException(error);
        console.error(error);
        setError(error);
      } finally {
        setIsSubmitting(false);
      }
    }
  });

  return (
    <form.Provider>
      <Fragment>
        <form
          className="flex flex-col space-y-6 w-full bg-secondary p-6 items-center border-y border-secondary"
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            void form.handleSubmit();
          }}
        >
          <div className="flex flex-col items-start w-full max-w-screen-md space-y-6">
            <h2>{t('Basic Info')}</h2>

            <div className="grid grid-cols-2 md:grid-cols-8 gap-4 md:items-end w-full">
              <form.Field name="firstName">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText={t('First Name')}
                      placeholder={t('First Name')}
                      subtitleText={t('First Name')}
                      required
                      disabled={isSubmitting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-3"
                      dataTestId="hca-firstname-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="middleName">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText={t('Middle Name')}
                      subtitleText={t('Middle Name')}
                      placeholder={t('Middle Name')}
                      disabled={isSubmitting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-2"
                      dataTestId="hca-middlename-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="lastName">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText={t('Last Name')}
                      placeholder={t('Last Name')}
                      subtitleText={t('Last Name')}
                      required
                      disabled={isSubmitting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="md:col-span-3"
                      dataTestId="hca-lastname-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="birthdate">
                {(field) => {
                  return (
                    <DateInput
                      fieldName={field.name}
                      value={field.state.value}
                      placeholderText={t('Birthdate')}
                      subtitleText={t('Your Date of Birth')}
                      required
                      disabled={isSubmitting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(updatedDate) => {
                        field.handleChange(updatedDate);
                      }}
                      containerClassName="md:col-span-4"
                      dataTestId="hca-birthdate-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field
                name="socialSecurityNumber"
                onBlur={(ssn) => {
                  if (!socialSecurityNumber().safeParse(ssn).success) {
                    return t('Invalid social security number');
                  }
                  return undefined;
                }}
              >
                {(field) => {
                  return (
                    <SocialSecurityNumberInput
                      value={field.state.value}
                      fieldName={field.name}
                      subtitleText={t('Social Security Number')}
                      placeholder={t('Social Security Number')}
                      disabled={isSubmitting}
                      required
                      error={field.state.meta.touchedErrors.at(0)?.toString()}
                      onBlur={field.handleBlur}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                      setFieldValue={(_fieldName, value) => {
                        form.setFieldValue(field.name, value);
                      }}
                      containerClassName="col-span-2 md:col-span-4"
                      dataTestId="hca-ssn-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="driversLicenseNumber">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      labelText={t("Driver's License Number")}
                      subtitleText={t("Driver's License Number")}
                      placeholder={t("Driver's License Number")}
                      required
                      disabled={isSubmitting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => {
                        field.handleChange(e.target.value);
                      }}
                      containerClassName="col-span-2 md:col-span-4"
                      dataTestId="hca-driver-license-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="driversLicenseState">
                {(field) => {
                  return (
                    <DropDown
                      fieldName={field.name}
                      value={field.state.value}
                      options={stateOptions}
                      labelText={t("Driver's State")}
                      subtitleText={t("Driver's State")}
                      placeholder={t("Driver's State")}
                      required
                      disabled={isSubmitting}
                      error={field.state.meta.touchedErrors.at(0)}
                      onChange={(e) => field.handleChange(e.target.value)}
                      containerClassName="col-span-2 md:col-span-4"
                      dataTestId="hca-driver-license-state-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="homePhoneNumber">
                {(field) => {
                  return (
                    <PhoneNumberInput
                      fieldName={field.name}
                      value={field.state.value}
                      subtitleText={t('Home Phone Number')}
                      placeholder={t('Home Phone Number')}
                      onChange={(value) =>
                        field.handleChange(value?.toString() ?? '')
                      }
                      onBlur={field.handleBlur}
                      disabled={isSubmitting}
                      containerClassName="col-span-2 md:col-span-4"
                      dataTestId="hca-homephone-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="phoneNumber">
                {(field) => {
                  return (
                    <PhoneNumberInput
                      fieldName={field.name}
                      value={field.state.value}
                      placeholder={t('Phone Number')}
                      subtitleText={t('Phone Number')}
                      onChange={(value) =>
                        field.handleChange(value?.toString() ?? '')
                      }
                      onBlur={field.handleBlur}
                      disabled={isSubmitting}
                      required
                      containerClassName="col-span-2 md:col-span-4"
                      dataTestId="hca-phone-input"
                    />
                  );
                }}
              </form.Field>

              <form.Field name="email">
                {(field) => {
                  return (
                    <TextInput
                      fieldName={field.name}
                      value={field.state.value}
                      isEmail
                      labelText={t('Email')}
                      placeholder={t('Email')}
                      subtitleText={t('Email')}
                      disabled
                      containerClassName="col-span-2 md:col-span-8"
                      dataTestId="hca-email-input"
                    />
                  );
                }}
              </form.Field>
            </div>
          </div>
        </form>

        {error && (
          <p className="text-negative text-end w-full max-w-screen-md">
            {error}
          </p>
        )}

        <div className="flex w-full px-6 justify-center">
          <div className="flex flex-row justify-end w-full max-w-screen-md">
            <form.Subscribe
              children={(f) => {
                const values = f.values;
                const canContinue =
                  f.isValid &&
                  !!values['firstName'] &&
                  !!values['lastName'] &&
                  !!values['birthdate'] &&
                  !!values['socialSecurityNumber'] &&
                  !!values['driversLicenseNumber'] &&
                  !!values['driversLicenseState'] &&
                  !!values['phoneNumber'] &&
                  !!values['email'];

                return (
                  <Button
                    label={t('Next')}
                    onClick={() => {
                      void form.handleSubmit();
                    }}
                    disabled={!canContinue}
                    isLoading={isSubmitting}
                    dataTestId="hca-next-button"
                  />
                );
              }}
            />
          </div>
        </div>
      </Fragment>
    </form.Provider>
  );
};

const HardCreditApplicationInfoPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { transactionId, hardCreditApplicationId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/hardCredit/:hardCreditApplicationId/basicInfo'
  );

  const { data: me, isFetched: fetchedMe } = useQuery(meQuery());
  const { data: customerData } = useQuery(
    customerQuery(transactionId, me?.me?.user?.id, dealershipSlug)
  );
  const { data, isFetched: fetchedTransaction } = useQuery(
    mkHardCreditQuery(transactionId)
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleCancel = () => {
    navigate('/dashboard/:dealershipSlug/:transactionId', {
      params: { transactionId, dealershipSlug }
    });
  };
  const { data: hca } = useQuery(
    hardCreditApplicationQuery(transactionId, hardCreditApplicationId)
  );
  const hardCreditApplication =
    hca?.transaction?.buyer?.hardCreditApplications?.edges?.at(0)?.node;
  const idCard = me?.me?.idCards?.at(0);

  return (
    <div className="flex flex-col h-dvh">
      <Header
        title={t('Hard Credit Application')}
        leftElement={
          <button
            className=" text-primary-brand"
            onClick={handleCancel}
            disabled={isSubmitting}
          >
            {t('Cancel')}
          </button>
        }
        totalSteps={5}
        currentStep={1}
      />

      <div className="flex w-full px-6 justify-center z-30">
        <div className="flex w-full max-w-screen-md self-center pt-4">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId"
            params={{ transactionId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>
      </div>

      <div className="flex flex-col py-10 space-y-6 w-full items-center overflow-y-scroll h-full">
        {fetchedMe && fetchedTransaction && customerData ? (
          <Fragment>
            <div
              className="flex w-full px-6 justify-center"
              data-test-id={'hca-basic-info-body'}
            >
              <div className="flex flex-col space-y-4 w-full max-w-screen-md bg-primary">
                <div className="space-y-4">
                  <h2>{t('Credit Application')}</h2>

                  <p>{t('Please read the information below.')}</p>
                </div>

                <div className="space-y-6 ">
                  <p>
                    {t(
                      'Filling out a Credit Application allows the dealer to submit your request for credit to the financial institutions to secure an approval for the loan. If the dealer submits the request for credit, it will be considered a Credit Inquiry.'
                    )}
                  </p>
                  <p>
                    <Trans>
                      <b>Compliance Notice:</b> A Hard-Credit Inquiry will only
                      be needed by the dealer when you are at the dealership,
                      have agreed to all the terms and conditions of your
                      Finance or Lease Agreements, and have decided to drive
                      away in your car.
                    </Trans>
                  </p>
                </div>
              </div>
            </div>

            <HardCreditBasicInfoForm
              customer={customerData}
              hardCreditApplication={hardCreditApplication}
              idCard={idCard}
              user={me?.me?.user}
              isSubmitting={isSubmitting}
              setIsSubmitting={setIsSubmitting}
            />
          </Fragment>
        ) : (
          <div className="flex w-full aspect-square justify-center items-center relative">
            <Spinner className="w-10 aspect-square" />
          </div>
        )}
      </div>
    </div>
  );
};

export default HardCreditApplicationInfoPage;
