type OverlaysProps = {
  heightPercentage?: number;
};

// Parent container should have a relative position!
const A4PageOverlay:  React.FC<OverlaysProps> = ({ heightPercentage = 50 }) => {
  const overlayStyles: React.CSSProperties = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 'auto', // Auto width to maintain aspect ratio
    height: `${heightPercentage}%`, // Height percentage of the parent VH
    transform: 'translate(-50%, -50%)',
    border: '2px dashed #F4F4F4',
    aspectRatio: '21 / 29.7', // Maintain A4 aspect ratio
    pointerEvents: 'none'
  };

  return <div className="opacity-30" style={overlayStyles} />;
};

export {
  A4PageOverlay
};
