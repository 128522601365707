import { FC } from 'react';

const UploadIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 30 31"
    >
      <title>Upload icon</title>

      <path
        d="M23.5727 26.6666H27.3346C27.8869 26.6666 28.3346 26.2189 28.3346 25.6666V2.66663C28.3346 2.11434 27.8869 1.66663 27.3346 1.66663H2.66797C2.11568 1.66663 1.66797 2.11434 1.66797 2.66663V25.6666C1.66797 26.2189 2.11568 26.6666 2.66797 26.6666H6.42987"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
      />

      <path
        d="M15.2941 29.9999C15.2941 21.73 15.2941 10.4901 15.2941 10.4901M15.2941 10.4901L10 15.7842M15.2941 10.4901L20.5882 15.7842"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadIcon;
