import { DocumentTemplate } from '@thedealersconcierge/lib/pdf-gen';
import { legalStamps } from '@thedealersconcierge/lib/pdf-gen/templates/legal-stamp';
import { TFunction } from 'i18next';

type Fields = 'image' | 'name' | 'dateTime' | 'deviceId' | 'ipAddress';
export const identityTheftPreventionForm: (
  t: TFunction
) => DocumentTemplate<Fields> = (t: TFunction) => ({
  fileUrl: t('identity-theft-prevention', {
    ns: 'files',
    defaultValue: '/docs/identity-theft-prevention.pdf'
  }),
  fields: {
    image: {
      formFieldId: 'form-identity-theft-prevention-field-1',
      formId: 'form-identity-theft-prevention',
      type: 'IMAGE',
      formFieldPlacements: [
        {
          formFieldPlacementId:
            'form-identity-theft-prevention-field-1-placement-1',
          positionX: 240,
          positionY: 400,
          page: 0,
          width: 133,
          height: 200,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      createdAt: ''
    },
    ...legalStamps('form-identity-theft-prevention', [0])
  }
});
