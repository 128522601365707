import { cloneElement, FC, Fragment, ReactElement, useState } from 'react';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { v4 } from 'uuid';

const Tooltip: FC<{
  /**
   * Anchor for the tooltip. This is the element that will trigger the tooltip.
   *
   * This can not be an icon (SVG) as we are cloning and adding a data-tooltip-id attribute to it.
   */
  anchor: ReactElement;

  /**
   * The content to put into the tooltip
   */
  content: ReactElement;

  /**
   * Hides the tooltip
   */
  hide?: boolean;
}> = ({ anchor, content, hide = false }) => {
  const [tooltipId] = useState(() => v4());

  return (
    <Fragment>
      {cloneElement(anchor, {
        'data-tooltip-id': tooltipId
      })}

      {!hide && (
        <ReactTooltip
          id={tooltipId}
          className="py-spacing-02 px-spacing-04 rounded-radius-01 bg-gray-900 !opacity-100 text-inverse max-w-40 tablet:max-w-52 desktop:max-w-64"
          offset={14} // React-tooltip calculates the offset to the box and not the tip. So for getting our desired offset to the tip of 8px we have to add the tip width.
        >
          {content}
        </ReactTooltip>
      )}
    </Fragment>
  );
};

export default Tooltip;
