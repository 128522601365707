import { GraphQLTypes, VehicleType } from '~/__generated__/backend/zeus';
import { gqlMutationClient } from '~/lib/backend';

const updateVehicleAction = async (
  transactionId: string,
  vehicleType: VehicleType,
  vehicleId: string,
  /**
   * We have to explicitly define null as an option here.
   * It is a known bug in v5 that graphql-zeus does not correctly generate null
   *
   * https://github.com/graphql-editor/graphql-zeus/issues/262
   *
   * It is fixed in v7 but that update requires a lot of changes
   */
  vehicle: Omit<
    GraphQLTypes['VehicleInput'],
    | 'pictureBackId'
    | 'pictureFrontId'
    | 'pictureInteriorId'
    | 'pictureLeftSideId'
    | 'pictureOdometerId'
    | 'pictureRightSideId'
    | 'pictureVinNumberId'
  > & {
    pictureBackId?: string | undefined | null;
    pictureFrontId?: string | undefined | null;
    pictureInteriorId?: string | undefined | null;
    pictureLeftSideId?: string | undefined | null;
    pictureOdometerId?: string | undefined | null;
    pictureRightSideId?: string | undefined | null;
    pictureVinNumberId?: string | undefined | null;
  }
) => {
  const resp = await gqlMutationClient()({
    updateVehicle: [
      {
        transactionId,
        vehicleType,
        vehicleId,
        vehicle
      },
      {
        __typename: true,
        '...on MutationUpdateVehicleSuccess': {
          data: {
            status: true
          }
        },
        '...on GraphQLError': {
          message: true
        }
      }
    ]
  });

  if (!resp.updateVehicle || resp.updateVehicle.__typename === 'GraphQLError') {
    throw new Error(resp.updateVehicle?.message ?? 'Unexpected error');
  }
};

export default updateVehicleAction;
