import {
  VehicleBodyType,
  VehicleCondition
} from '~/__generated__/backend/zeus';

/**
 * This is a helper function. When adding new enums, they have to be included here as  well
 *
 * @param s
 * @returns
 */
export const stringToVehicleCondition = (
  s: string
): VehicleCondition | undefined => {
  const VehicleConditionMap: { [key: string]: VehicleCondition } = {
    EXCELLENT: VehicleCondition.EXCELLENT,
    GOOD: VehicleCondition.GOOD,
    SATISFACTORY: VehicleCondition.SATISFACTORY,
    UNSATISFACTORY: VehicleCondition.UNSATISFACTORY
  };

  return VehicleConditionMap[s];
};

/**
 * This is a helper function. When adding new enums, they have to be included here as  well
 *
 * @param s
 * @returns
 */
export const stringToVehicleBodyType = (
  s: string
): VehicleBodyType | undefined => {
  const VehicleBodyTypeMap: { [key: string]: VehicleBodyType } = {
    COUPE: VehicleBodyType.COUPE,
    MINIVAN: VehicleBodyType.MINIVAN,
    OTHER: VehicleBodyType.OTHER,
    SEDAN: VehicleBodyType.SEDAN,
    SUV: VehicleBodyType.SUV
  };

  return VehicleBodyTypeMap[s];
};
