import { GraphQLTypes } from '~/__generated__/backend/zeus';
import { gqlMutationClient } from '~/lib/backend';

const updateCustomerAction = async (
  transactionId: string,
  customer: GraphQLTypes['UpdateCustomerInput']
) => {
  const respCustomer = await gqlMutationClient()({
    updateCustomer: [
      {
        transactionId,
        customer
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationUpdateCustomerSuccess': {
          data: { status: true }
        }
      }
    ]
  });

  if (
    !respCustomer.updateCustomer ||
    respCustomer.updateCustomer.__typename === 'GraphQLError'
  ) {
    throw new Error(respCustomer.updateCustomer?.message);
  }
};

export default updateCustomerAction;
