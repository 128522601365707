import { Component, ErrorInfo, ReactNode } from 'react';

// This is a custom error boundary that will catch any unhandled errors that occur in the children components
// This is used instead of react-error-boundary because we need a custom toast message
// Using react-error-boundary always require a FallbackComponent to be rendered
interface CustomErrorBoundaryProps {
  children: ReactNode;
  onError: (error: Error, errorInfo: ErrorInfo) => void;
}

interface CustomErrorBoundaryState {
  hasError: boolean;
}

class CustomErrorBoundary extends Component<
  CustomErrorBoundaryProps,
  CustomErrorBoundaryState
> {
  state: CustomErrorBoundaryState = { hasError: false };

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.setState({ hasError: true });
    this.props.onError(error, errorInfo);
  }

  render() {
    // TODO: Handle rendering of fallback component
    if (this.state.hasError) {
      return this.props.children;
    }
    return this.props.children;
  }
}

export default CustomErrorBoundary;
