import { generatePdf } from '@thedealersconcierge/lib/pdf-gen';
import { TFunction } from 'i18next';
import { creditApplicationForm } from '~/lib/form/credit-form';
import { AddressType } from '~/querySelectors/address';
import { DealershipType } from '~/querySelectors/dealership';
import { EmploymentType } from '~/querySelectors/employment';
import { HardCreditApplicationType } from '~/querySelectors/hardCreditApplication';
import { UserType } from '~/querySelectors/userSelector';
import uploadFileAction from '../uploadFileAction';
import generateHardCreditApplicationFormSubmissionData from './generateHardCreditApplicationFormSubmissionData';

export const uploadHardCreditApplicationPdfAction = async (
  user: UserType,
  dealership: DealershipType,
  hardCreditApplication: HardCreditApplicationType,
  addresses: AddressType[],
  employments: EmploymentType[],
  signature: string,
  ipAddress: string,
  t: TFunction,
  signatureName?: string
) => {
  const formSubmissionData = generateHardCreditApplicationFormSubmissionData(
    user,
    dealership,
    hardCreditApplication,
    addresses,
    employments,
    signature,
    ipAddress,
    t,
    signatureName
  );

  // Generate hard credit application
  const applicationPdf = await generatePdf(
    creditApplicationForm(t),
    formSubmissionData.submissionData,
    false
  );
  const applicationPdfBlob = new Blob([applicationPdf]);

  const uploadResponse = await uploadFileAction(
    'pdf',
    'application/pdf',
    applicationPdfBlob
  );

  return [uploadResponse, formSubmissionData] as const;
};
