import useWindowSize from '@thedealersconcierge/lib/hooks/useWindowSize';
import { Image } from 'image-js';
import { FC, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Webcam from 'react-webcam';
import Button from './Button';
import Modal from './Modal';
import Spinner from './Spinner';
import CameraIcon from './icons/CameraIcon';
import ChevronRightIcon from './icons/ChevronRightIcon';

type Orientation = 'PORTRAIT' | 'LANDSCAPE';

/**
 * This component is only meant to be used on desktop.
 * For capturing images on mobile devices, we are using the camera file inputs.
 *
 * @param param0
 * @returns
 */
const DesktopWebcamModal: FC<{
  isOpen: boolean;
  title: string;
  orientation: Orientation;
  onClose: () => void;
  onDone: (file: File) => void;
}> = ({ isOpen, title, orientation, onClose, onDone }) => {
  const { t } = useTranslation();
  const windowSize = useWindowSize();
  const webcamRef = useRef<Webcam | null>(null);
  const [cameraLoaded, setCameraLoaded] = useState(false);
  const [image, setImage] = useState<File | undefined>(undefined);
  const handleCameraLoaded = () => {
    setCameraLoaded(true);
  };
  const takePicture = async () => {
    if (webcamRef.current) {
      const screenshot = webcamRef.current.getScreenshot() ?? '';
      const image = await Image.load(screenshot);
      const imageBlob = await image.flipX().toBlob('image/jpeg');

      setImage(new File([imageBlob], 'image.jpeg', { type: 'image/jpeg' }));
    }
  };
  const handleRetake = () => {
    setImage(undefined);
  };
  const getAspectRatio = () => {
    return orientation === 'LANDSCAPE' ? 1.5 : 2 / 3;
  };
  const getHeight = () => {
    return windowSize.height * 0.5;
  };
  const getWidth = () => {
    return getHeight() * getAspectRatio();
  };
  const handleClose = () => {
    onClose();
    setImage(undefined);
  };
  const handleDone = (imageFile: File) => {
    onDone(imageFile);
    setImage(undefined);
  };

  return (
    <Modal
      isOpen={isOpen}
      className="flex flex-col w-dvw h-dvh !max-w-full rounded-none py-8"
    >
      <div className="relative flex flex-row justify-center items-center w-full">
        <div className="absolute left-2">
          <Button onClick={handleClose} variant="TEXT_ONLY">
            <ChevronRightIcon className="size-8 -scale-x-100 icon-tertiary" />
          </Button>
        </div>

        <h3
          className="capitalize text-center"
          data-test-id={'desktop-webcam-modal'}
        >
          {title}
        </h3>
      </div>

      <div className="flex flex-1 flex-col relative items-center">
        {/**
         * The user hasn't taken an image yet
         */}
        {!image ? (
          <div className="flex flex-col size-full space-y-8 items-center justify-center">
            <div
              className="rounded-lg overflow-hidden"
              style={{ width: getWidth(), height: getHeight() }}
            >
              <Webcam
                ref={webcamRef}
                audio={false}
                className="size-full -scale-x-100"
                screenshotQuality={1}
                screenshotFormat="image/png"
                imageSmoothing={false}
                videoConstraints={{
                  width: {
                    ideal: getWidth() * 2 // The creates a better resolution of the video stream
                  },
                  height: {
                    ideal: getHeight() * 2 // The creates a better resolution of the video stream
                  },
                  aspectRatio: {
                    ideal: getAspectRatio()
                  }
                }}
                onUserMedia={handleCameraLoaded}
              />
            </div>

            <div
              data-test-id="webcam-capture-button"
              className="flex size-16 items-center justify-center rounded-full border cursor-pointer icon-interactive border-interactive"
              onClick={() => void takePicture()}
            >
              <CameraIcon className="size-10" filled />
            </div>

            {!cameraLoaded && (
              <div className="absolute flex top-0 right-0 bottom-0 left-0 items-center justify-center">
                <Spinner />
              </div>
            )}
          </div>
        ) : (
          // The user has taken an image
          <div className="flex flex-col size-full space-y-8 items-center justify-center">
            <div className="rounded-lg overflow-hidden h-fit">
              <div style={{ width: getWidth(), height: getHeight() }}>
                <img src={URL.createObjectURL(image)} className="size-full" />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-2 min-h-16 items-center">
              <Button variant="SECONDARY" onClick={handleRetake}>
                {t('Retake')}
              </Button>

              <Button
                onClick={() => {
                  handleDone(image);
                }}
                dataTestId="webcam-capture-accept"
              >
                {t('Accept and continue')}
              </Button>
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default DesktopWebcamModal;
