import { z } from "zod";

export const FormFieldPlacementSchema = z.object({
  id: z.string().uuid(),
  formFieldPlacementId: z.string(),
  formFieldId: z.string().uuid(),
  page: z.number().int(),
  positionX: z.number(),
  positionY: z.number(),
  width: z.number(),
  height: z.number(),
  rotation: z.number(),
  textFontSize: z.number(),
  textItalic: z.boolean(),
  textBold: z.boolean(),
  textUnderline: z.boolean(),
  characterListSpacing: z.number(),
  createdAt: z.string(),
});

export type FormFieldPlacementSchema = z.TypeOf<
  typeof FormFieldPlacementSchema
>;

export const FormBuilderFormFieldPlacementSchema =
  FormFieldPlacementSchema.pick({
    formFieldPlacementId: true,
    page: true,
    positionX: true,
    positionY: true,
    width: true,
    height: true,
    rotation: true,
    textFontSize: true,
    textItalic: true,
    textBold: true,
    textUnderline: true,
    characterListSpacing: true,
  })
    .partial({ rotation: true, textFontSize: true, characterListSpacing: true })
    .extend({
      textFontSize: z.number().nullish(),
      characterListSpacing: z.number().nullish(),
    });

export type FormBuilderFormFieldPlacementSchema = z.TypeOf<
  typeof FormBuilderFormFieldPlacementSchema
>;
