import {
  GraphQLTypes,
  InputType,
  Selector
} from '~/__generated__/backend/zeus';
import { gqlQueryClient } from '~/lib/backend';
import { queryClient } from '~/main';
import { idCardSelector } from '~/querySelectors/idCard';
import { userSelector } from '~/querySelectors/userSelector';

const queryKey = ['profile'];
// Define select here to derive typing
const selector = Selector('Query')({
  me: {
    ipAddress: true,
    user: userSelector,

    dealerships: {
      id: true,
      name: true
    },
    idCards: idCardSelector
  }
});
export type MeQueryType = InputType<GraphQLTypes['Query'], typeof selector>;

/**
 * This query can be used in all react components. The reason why we store it
 * on the outermost level her (and not just the selector) is to ensure that we
 * reuse the query selector.
 *
 * @returns
 */
export default function meQuery() {
  return {
    queryKey,
    queryFn: () => {
      return gqlQueryClient()(selector);
    }
  };
}

export const resetMeQuery = async () => {
  await queryClient.resetQueries({
    queryKey
  });
};
