import { parseISO } from 'date-fns';
import { parsePhoneNumber } from 'libphonenumber-js';

export const getDateFromUnkown = (date: unknown): Date | undefined => {
  if (!date) {
    return undefined;
  }

  // If the object is a date, typeof returns "object"
  if (typeof date === 'object') {
    return date as Date;
  }

  return parseISO(String(date));
};

export const formatPhoneNumber = (phoneNumber?: string) => {
  return phoneNumber
    ? parsePhoneNumber(phoneNumber)?.format(
        parsePhoneNumber(phoneNumber)?.country === 'US'
          ? 'NATIONAL'
          : 'INTERNATIONAL'
      )
    : undefined;
};
