import classNames from 'classnames';
import { FC, ReactNode } from 'react';
import CameraIcon from './CameraIcon';

const BottomLeftCornerIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 52 52"
    >
      <title>Bottom left corner icon</title>

      <path
        d="M2 0V48C2 49.1046 2.89543 50 4 50H52"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const BottomRightCornerIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 52 52"
    >
      <title>Bottom right corner icon</title>

      <path
        d="M50 0V48C50 49.1046 49.1046 50 48 50H0"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const TopLeftCornerIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 52 52"
    >
      <title>Top left corner icon</title>

      <path
        d="M52 2L4 2C2.89543 2 2 2.89543 2 4L2 52"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const TopRightCornerIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 52 52"
    >
      <title>Top right corner icon</title>

      <path
        d="M0 2L48 2C49.1046 2 50 2.89543 50 4L50 52"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CaptureIcon: FC<{ centerIcon?: ReactNode; className?: string }> = ({
  centerIcon,
  className
}) => {
  return (
    <div
      className={classNames(
        'flex flex-col w-full items-center justify-between',
        className
      )}
    >
      <div className="flex flex-row justify-between w-full">
        <div className="relative">
          <TopLeftCornerIcon className="w-12  " />
        </div>

        <div className="relative">
          <TopRightCornerIcon className="w-12  " />
        </div>
      </div>

      {centerIcon ?? (
        <div className="relative">
          <CameraIcon className="w-20  " />
        </div>
      )}

      <div className="flex flex-row justify-between w-full">
        <div className="relative">
          <BottomLeftCornerIcon className="w-12  " />
        </div>

        <div className="relative">
          <BottomRightCornerIcon className="w-12  " />
        </div>
      </div>
    </div>
  );
};

export default CaptureIcon;
