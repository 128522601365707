import { FC } from 'react';

const DocumentIcon: FC<{ className?: string }> = ({ className }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 33 32"
    >
      <title>Document icon</title>

      <path
        d="M21.5 1V9H29.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
      />

      <path
        d="M21.5 1H3.5V31H29.5V9L21.5 1Z"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />

      <path
        d="M9.5 22H23.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />

      <path
        d="M9.5 16H23.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />

      <path
        d="M9.5 10H15.5"
        stroke="currentColor"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="square"
      />
    </svg>
  );
};

export default DocumentIcon;
