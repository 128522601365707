import {
  GraphQLTypes,
  InputType,
  Selector
} from '~/__generated__/backend/zeus';

export const idCardSelector = Selector('IdCard')({
  id: true,
  type: true,
  stateInAddress: true,
  state: true,
  streetInAddress: true,
  zipCodeInAddress: true,
  cityInAddress: true,
  idNumber: true,
  firstName: true,
  lastName: true,
  middleName: true,
  expirationDateOnly: true,
  captureBack: {
    id: true,
    url: true
  },
  captureFront: {
    id: true,
    url: true
  },
  transactionId: true,
  dobDateOnly: true
});

export type IdCardType = InputType<
  GraphQLTypes['IdCard'],
  typeof idCardSelector
>;
