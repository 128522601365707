import { DocumentTemplate } from '@thedealersconcierge/lib/pdf-gen';
import { legalStamps } from '@thedealersconcierge/lib/pdf-gen/templates/legal-stamp';
import { TFunction } from 'i18next';

type Fields =
  | 'firstName'
  | 'middleName'
  | 'lastName'
  | 'streetAddress'
  | 'city'
  | 'state'
  | 'zip'
  | 'birthdate'
  | 'socialSecurityNumber'
  | 'phoneNumber'
  | 'addressDuration'
  | 'residentialStatus'
  | 'mortgageOrRentMonthlyPay'
  | 'previousAddressLength'
  | 'employerName'
  | 'employerPhoneNumber'
  | 'employerAddress'
  | 'jobTitle'
  | 'employmentStatus'
  | 'timeOnJob'
  | 'salary'
  | 'type'
  | 'prevEmployerInfo'
  | 'otherIncmeSource'
  | 'otherIncomeAmount'
  | 'otherIncomeType'
  | 'refNameAddress'
  | 'refPhoneNumber'
  | 'refRelationship'
  | 'signature'
  | 'customerName'
  | 'dealershipName'
  | 'previousAddress'
  // For legal stamps
  | 'name'
  | 'dateTime'
  | 'deviceId'
  | 'ipAddress';

export const creditApplicationForm: (
  t: TFunction
) => DocumentTemplate<Fields> = (t: TFunction) => ({
  fileUrl: t('credit-applications-template', {
    ns: 'files',
    defaultValue: '/docs/credit-applications-template.pdf'
  }),
  fields: {
    ...legalStamps('form-credit-form', [0, 1, 2, 3, 4]),

    // First line
    firstName: {
      formFieldId: 'form-credit-form-field-1',
      formId: 'form-credit-form',
      demoData: 'FirstName',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-1-placement-1',
          positionX: 20,
          positionY: 81,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    middleName: {
      formFieldId: 'form-credit-form-field-2',
      formId: 'form-credit-form',
      demoData: 'MiddleName',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-2-placement-1',
          positionX: 215,
          positionY: 81,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    lastName: {
      formFieldId: 'form-credit-form-field-3',
      formId: 'form-credit-form',
      demoData: 'LastName',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-3-placement-1',
          positionX: 413,
          positionY: 81,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },

    // Second line
    birthdate: {
      formFieldId: 'form-credit-form-field-4',
      formId: 'form-credit-form',
      demoData: '10/06/1967',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-4-placement-1',
          positionX: 20,
          positionY: 126,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    socialSecurityNumber: {
      formFieldId: 'form-credit-form-field-5',
      formId: 'form-credit-form',
      demoData: '234 543 3456',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-5-placement-1',
          positionX: 215,
          positionY: 126,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    phoneNumber: {
      formFieldId: 'form-credit-form-field-6',
      formId: 'form-credit-form',
      demoData: '(234) 234 3452',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-6-placement-1',
          positionX: 413,
          positionY: 126,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },

    // Third line
    streetAddress: {
      formFieldId: 'form-credit-form-field-7',
      formId: 'form-credit-form',
      demoData: 'Street Addr 104',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-7-placement-1',
          positionX: 20,
          positionY: 176,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    city: {
      formFieldId: 'form-credit-form-field-8',
      formId: 'form-credit-form',
      demoData: 'City',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-8-placement-1',
          positionX: 215,
          positionY: 176,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    state: {
      formFieldId: 'form-credit-form-field-9',
      formId: 'form-credit-form',
      demoData: 'State',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-9-placement-1',
          positionX: 413,
          positionY: 176,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    zip: {
      formFieldId: 'form-credit-form-field-10',
      formId: 'form-credit-form',
      demoData: 'State',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-10-placement-1',
          positionX: 520,
          positionY: 176,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },

    // Fourth line
    residentialStatus: {
      formFieldId: 'form-credit-form-field-11',
      formId: 'form-credit-form',
      demoData: 'employee',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-11-placement-1',
          positionX: 20,
          positionY: 226,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    addressDuration: {
      formFieldId: 'form-credit-form-field-12',
      formId: 'form-credit-form',
      demoData: '0 years 3 months',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-12-placement-1',
          positionX: 215,
          positionY: 226,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    mortgageOrRentMonthlyPay: {
      formFieldId: 'form-credit-form-field-13',
      formId: 'form-credit-form',
      demoData: 'Mortgage',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-13-placement-1',
          positionX: 413,
          positionY: 226,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },

    // Fifth
    previousAddress: {
      formFieldId: 'form-credit-form-field-14',
      formId: 'form-credit-form',
      demoData: 'Prev Address 4',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-14-placement-1',
          positionX: 20,
          positionY: 265,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    previousAddressLength: {
      formFieldId: 'form-credit-form-field-15',
      formId: 'form-credit-form',
      demoData: '1 year 3 months',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-15-placement-1',
          positionX: 310,
          positionY: 265,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },

    // Sixth
    employerName: {
      formFieldId: 'form-credit-form-field-16',
      formId: 'form-credit-form',
      demoData: 'JohnDoe Ltd.',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-16-placement-1',
          positionX: 20,
          positionY: 321,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    employerPhoneNumber: {
      formFieldId: 'form-credit-form-field-17',
      formId: 'form-credit-form',
      demoData: '(373) 543 3457',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-17-placement-1',
          positionX: 310,
          positionY: 321,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },

    // Seventh
    employerAddress: {
      formFieldId: 'form-credit-form-field-18',
      formId: 'form-credit-form',
      demoData: 'Some Address 4',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-18-placement-1',
          positionX: 20,
          positionY: 360,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    jobTitle: {
      formFieldId: 'form-credit-form-field-19',
      formId: 'form-credit-form',
      demoData: 'Carpenter',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-19-placement-1',
          positionX: 310,
          positionY: 360,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },

    // 8th
    employmentStatus: {
      formFieldId: 'form-credit-form-field-20',
      formId: 'form-credit-form',
      demoData: 'Employed',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-20-placement-1',
          positionX: 20,
          positionY: 410,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    timeOnJob: {
      formFieldId: 'form-credit-form-field-21',
      formId: 'form-credit-form',
      demoData: '2 year 5 months',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-21-placement-1',
          positionX: 310,
          positionY: 410,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },

    // 9th
    salary: {
      formFieldId: 'form-credit-form-field-22',
      formId: 'form-credit-form',
      demoData: '$ 50.000',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-22-placement-1',
          positionX: 20,
          positionY: 461,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    type: {
      formFieldId: 'form-credit-form-field-23',
      formId: 'form-credit-form',
      demoData: 'Some type?',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-23-placement-1',
          positionX: 310,
          positionY: 461,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },

    // 10th
    prevEmployerInfo: {
      formFieldId: 'form-credit-form-field-24',
      formId: 'form-credit-form',
      demoData: 'John Doe, some street 43, NY',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-24-placement-1',
          positionX: 20,
          positionY: 511,
          page: 0,
          width: 550,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },

    // 11th
    otherIncmeSource: {
      formFieldId: 'form-credit-form-field-25',
      formId: 'form-credit-form',
      demoData: 'Knitting',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-25-placement-1',
          positionX: 20,
          positionY: 606,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    otherIncomeAmount: {
      formFieldId: 'form-credit-form-field-26',
      formId: 'form-credit-form',
      demoData: '$ 60.000',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-26-placement-1',
          positionX: 215,
          positionY: 606,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    otherIncomeType: {
      formFieldId: 'form-credit-form-field-27',
      formId: 'form-credit-form',
      demoData: '---',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-27-placement-1',
          positionX: 413,
          positionY: 606,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },

    // 12th
    refNameAddress: {
      formFieldId: 'form-credit-form-field-28',
      formId: 'form-credit-form',
      demoData: 'John Doe, Doe Street 3',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-28-placement-1',
          positionX: 20,
          positionY: 676,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    refPhoneNumber: {
      formFieldId: 'form-credit-form-field-29',
      formId: 'form-credit-form',
      demoData: '(234) 235 2343',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-29-placement-1',
          positionX: 215,
          positionY: 676,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    refRelationship: {
      formFieldId: 'form-credit-form-field-30',
      formId: 'form-credit-form',
      demoData: 'Aunt',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-30-placement-1',
          positionX: 413,
          positionY: 676,
          page: 0,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },

    // Page 2 stuff
    dealershipName: {
      formFieldId: 'form-credit-form-field-31',
      formId: 'form-credit-form',
      demoData: 'Joe Dealer',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-31-placement-1',
          positionX: 130,
          positionY: 261,
          page: 1,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    customerName: {
      formFieldId: 'form-credit-form-field-32',
      formId: 'form-credit-form',
      demoData: 'John Doe',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-32-placement-1',
          positionX: 85,
          positionY: 541,
          page: 1,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'TEXT',
      createdAt: '2024-05-21 00:00:00.000'
    },
    signature: {
      formFieldId: 'form-credit-form-field-33',
      formId: 'form-credit-form',
      demoData: 'John Doe',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-credit-form-field-33-placement-1',
          positionX: 200,
          positionY: 640,
          page: 1,
          width: 150,
          height: 30,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      type: 'SIGNATURE',
      createdAt: '2024-05-21 00:00:00.000'
    }
  }
});
