import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import logoutAction from '~/actions/logoutAction';
import Button from '~/components/Button';
import Spinner from '~/components/Spinner';
import ChevronRightIcon from '~/components/icons/ChevronRightIcon';
import DocumentsIcon from '~/components/icons/DocumentsIcon';
import Checkbox from '~/components/inputs/Checkbox';
import TextInput from '~/components/inputs/TextInput';
import meQuery from '~/queries/meQuery';
import { Link, useNavigate, useParams } from '~/router';
import ConsumerDashboardHeader from '../_components/ConsumerDashboardHeader';

export const AdditionalDocumentMaps = {
  BANK_STATEMENTS: 'Bank Statements',
  UTILITY_BILLS: 'Utility Bills',
  PROOF_OF_INCOME: 'Proof of Income',
  OTHER: 'Other'
} as const;

export type DocumentType =
  | 'BANK_STATEMENTS'
  | 'UTILITY_BILLS'
  | 'PROOF_OF_INCOME'
  | 'OTHER';

const AddAdditionalDocuments = () => {
  const navigate = useNavigate();
  const { transactionId, dealershipSlug } = useParams(
    '/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments'
  );
  const [loggingOut, setLoggingOut] = useState(false);
  const handleLogout = async () => {
    setLoggingOut(true);
    await logoutAction();
  };
  const { isLoading: loadingUserData } = useQuery(meQuery());
  const [documentType, setDocumentType] =
    useState<DocumentType>('BANK_STATEMENTS');
  const [documentName, setDocumentName] = useState<string>(
    AdditionalDocumentMaps.BANK_STATEMENTS // Bank statements is the preselected option
  );

  const handleNavigateToUploadPage = () => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments/:documentType',
      {
        params: { transactionId, documentType, dealershipSlug },
        state: { documentName }
      }
    );
  };

  const handleNavigateToCapturePage = () => {
    navigate(
      '/dashboard/:dealershipSlug/:transactionId/addAdditionalDocuments/:documentType/capture',
      {
        params: { transactionId, documentType, dealershipSlug },
        state: { documentName }
      }
    );
  };

  return (
    <div className="flex flex-col h-dvh w-full">
      <ConsumerDashboardHeader
        centerElement={
          <div className="flex flex-row space-x-2">
            <div className="relative">
              <DocumentsIcon className="size-4 md:size-5 icon-primary" />
            </div>

            <h2 className="hidden md:flex items-center">Documents & Forms</h2>

            <h3 className="flex md:hidden items-center">Documents & Forms</h3>
          </div>
        }
        rightElement={
          <Button
            variant="TEXT_ONLY"
            size="SMALL"
            onClick={handleLogout}
            loading={loggingOut}
            className="!p-0"
          >
            <span className="text-secondary">Logout</span>
          </Button>
        }
        showBorder
      />

      <div className="flex flex-col pt-4 space-y-6 w-full items-center overflow-y-scroll">
        <div className="w-full max-w-screen-md px-6 md:px-0">
          <Link
            to="/dashboard/:dealershipSlug/:transactionId"
            params={{ transactionId, dealershipSlug }}
            className="flex w-6"
          >
            <div className="relative">
              <ChevronRightIcon className="w-6 -scale-x-100 icon-tertiary" />
            </div>
          </Link>
        </div>

        {loadingUserData && (
          <div className="flex w-full max-w-screen-md aspect-square justify-center items-center relative">
            <Spinner className="w-10 aspect-square" />
          </div>
        )}

        {!loadingUserData && (
          <div className="flex flex-col space-y-4 w-full items-center">
            <div className="space-y-2 w-full px-6 md:px-0 max-w-screen-md">
              <h2>Add Documents</h2>

              <p>
                Please upload documents or capture them using the device camera.
              </p>
            </div>

            <div className="flex flex-col w-full border-y border-secondary bg-secondary p-6 items-center">
              <div className="space-y-6 w-full max-w-screen-md">
                <div className="space-y-3">
                  {Object.entries(AdditionalDocumentMaps).map(
                    ([key, value]) => (
                      <div
                        className="flex flex-row items-center space-x-2"
                        key={key}
                      >
                        <Checkbox
                          inputId={value}
                          variant="RADIO_BUTTON"
                          value={documentType === key}
                          onChange={() => {
                            setDocumentType(key as DocumentType);

                            if (key !== 'OTHER')
                              setDocumentName(
                                AdditionalDocumentMaps[key as DocumentType] ??
                                  key
                              );
                          }}
                        />
                        <span className="ml-2 text-gray-700">{value}</span>
                      </div>
                    )
                  )}

                  {documentType == 'OTHER' && (
                    <TextInput
                      fieldName={'Document name'}
                      labelText="Document name"
                      subtitleText="Document name"
                      placeholder="Enter Document name"
                      required={documentType == 'OTHER'}
                      containerClassName="col-span-4 mt-2 w-1/3"
                      onChange={(e) => setDocumentName(e.target.value)}
                    />
                  )}
                </div>

                <div className="flex flex-row gap-4 mt-6">
                  <Button
                    size="DEFAULT"
                    disabled={documentType === 'OTHER' && !documentName.trim()}
                    onClick={handleNavigateToUploadPage}
                  >
                    Upload
                  </Button>

                  <Button
                    size="DEFAULT"
                    variant="SECONDARY"
                    disabled={documentType === 'OTHER' && !documentName.trim()}
                    onClick={handleNavigateToCapturePage}
                  >
                    Capture via Camera
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default AddAdditionalDocuments;
