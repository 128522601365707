import {
  GraphQLTypes,
  InputType,
  Selector
} from '~/__generated__/backend/zeus';
import { addressSelector } from './address';

export const employmentSelector = Selector('Employment')({
  id: true,
  userId: true,
  transactionId: true,
  timelinePosition: true,
  employmentStatus: true,
  employerName: true,
  occupation: true,
  employerPhoneNumber: true,
  employerAddress: addressSelector,
  durationYears: true,
  durationMonths: true,
  incomeAmount: true,
  incomeInterval: true,
  otherIncomeSource: true,
  otherIncomeAmount: true,
  otherIncomeInterval: true
});

export type EmploymentType = InputType<
  GraphQLTypes['Employment'],
  typeof employmentSelector
>;
