import { TFunction } from 'i18next';

/**
 * We can do this because it is a part of the project.
 *
 * NOTE: Moving this function into a shared folder will need updates to the translations key extractor
 *
 * @param t
 * @returns
 */
const housingStatusOptions = (t: TFunction) => [
  { value: 'OWNING', label: t('Owning') },
  { value: 'RENTING', label: t('Renting') },
  { value: 'LIVING_WITH_RELATIVES', label: t('Living with relatives') },
  { value: 'OTHER', label: t('Other') }
];

export default housingStatusOptions;
