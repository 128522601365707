import { TFunction } from 'i18next';

const employmentStatusOptions = (t: TFunction) => [
  { value: 'EMPLOYED', label: t('Employed') },
  { value: 'SELF_EMPLOYED', label: t('Self-employed') },
  { value: 'STUDENT', label: t('Student') },
  { value: 'RETIRED', label: t('Retired') },
  { value: 'UNEMPLOYED', label: t('unemployed') }
];

export default employmentStatusOptions;
