import { CognitoUser } from 'amazon-cognito-identity-js';
import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';

type CognitoAuthData = {
  accessToken: string;
  idToken: string;
  refreshToken: string;
};

export const isCognitoAuth = (v: any): v is CognitoAuthData => {
  return Boolean(v?.accessToken);
};

type BackendAuthToken = {
  jwt: string;
};

export const authStateAtom = atomWithStorage<
  CognitoAuthData | BackendAuthToken | null
>('authStateAtom', null);

export const cognitoUserAtom = atom<null | CognitoUser>(null);
