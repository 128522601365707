import { DocumentTemplate } from '@thedealersconcierge/lib/pdf-gen';

type Fields = 'front' | 'back';
export const dmvDocument: DocumentTemplate<Fields> = {
  fileUrl: '/docs/empty-a4.pdf',
  fields: {
    front: {
      formFieldId: 'form-dmv-field-1',
      formId: 'form-dmv',
      type: 'IMAGE',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-dmv-field-1-placement-1',
          positionX: 80,
          positionY: 40,
          page: 0,
          width: 500,
          height: 300,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      createdAt: ''
    },
    back: {
      formFieldId: 'form-dmv-field-1',
      formId: 'form-dmv',
      type: 'IMAGE',
      formFieldPlacements: [
        {
          formFieldPlacementId: 'form-dmv-field-1-placement-1',
          positionX: 80,
          positionY: 400,
          page: 0,
          width: 500,
          height: 300,
          textFontSize: null,
          characterListSpacing: null,
          textItalic: false,
          textBold: false,
          textUnderline: false
        }
      ],
      createdAt: ''
    }
  }
};
