import { FC } from 'react';

const CameraIcon: FC<{ filled?: boolean; className?: string }> = ({
  filled,
  className
}) => {
  if (!filled) {
    return (
      <svg
        className={className}
        xmlns="http://www.w3.org/2000/svg"
        width="100%"
        height="100%"
        fill="none"
        viewBox="0 0 89 89"
      >
        <title>Camera icon</title>

        <path
          d="M44.5 59.3333C50.6442 59.3333 55.625 54.3524 55.625 48.2083C55.625 42.0641 50.6442 37.0833 44.5 37.0833C38.3558 37.0833 33.375 42.0641 33.375 48.2083C33.375 54.3524 38.3558 59.3333 44.5 59.3333Z"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <path
          d="M74.1667 25.9583H14.8333C12.7853 25.9583 11.125 27.6185 11.125 29.6666V66.7499C11.125 68.798 12.7853 70.4583 14.8333 70.4583H74.1667C76.2147 70.4583 77.875 68.798 77.875 66.7499V29.6666C77.875 27.6185 76.2147 25.9583 74.1667 25.9583Z"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />

        <path
          d="M52.9522 14.8333H36.0478C34.4516 14.8333 33.0345 15.8546 32.5298 17.3689L31.2937 21.0772C30.4933 23.4785 32.2806 25.9583 34.8117 25.9583H54.1883C56.7194 25.9583 58.5068 23.4785 57.7063 21.0772L56.4702 17.3689C55.9655 15.8546 54.5484 14.8333 52.9522 14.8333Z"
          stroke="currentColor"
          strokeWidth="4"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    );
  }

  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      fill="none"
      viewBox="0 0 42 42"
    >
      <title>Camera icon</title>

      <path
        d="M35.3086 10.7563H29.7803L28.4632 7.06538C28.3722 6.81282 28.2055 6.59451 27.9858 6.44026C27.7661 6.28602 27.5041 6.20338 27.2356 6.20361H14.7644C14.2156 6.20361 13.7238 6.54913 13.5408 7.06538L12.2197 10.7563H6.69141C4.8947 10.7563 3.43945 12.2116 3.43945 14.0083V32.5444C3.43945 34.3411 4.8947 35.7964 6.69141 35.7964H35.3086C37.1053 35.7964 38.5605 34.3411 38.5605 32.5444V14.0083C38.5605 12.2116 37.1053 10.7563 35.3086 10.7563ZM21 29.2925C17.4066 29.2925 14.4961 26.382 14.4961 22.7886C14.4961 19.1952 17.4066 16.2847 21 16.2847C24.5934 16.2847 27.5039 19.1952 27.5039 22.7886C27.5039 26.382 24.5934 29.2925 21 29.2925ZM17.0977 22.7886C17.0977 23.8235 17.5088 24.8161 18.2406 25.5479C18.9725 26.2798 19.965 26.6909 21 26.6909C22.035 26.6909 23.0275 26.2798 23.7594 25.5479C24.4912 24.8161 24.9023 23.8235 24.9023 22.7886C24.9023 21.7536 24.4912 20.761 23.7594 20.0292C23.0275 19.2974 22.035 18.8862 21 18.8862C19.965 18.8862 18.9725 19.2974 18.2406 20.0292C17.5088 20.761 17.0977 21.7536 17.0977 22.7886Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CameraIcon;
