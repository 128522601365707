import { gqlMutationClient } from '~/lib/backend';

const deleteAddressAction = async (addressId: string) => {
  const resp = await gqlMutationClient()({
    deleteAddress: [
      {
        id: addressId
      },
      {
        __typename: true,
        '...on GraphQLError': {
          message: true
        },
        '...on MutationDeleteAddressSuccess': {
          data: {
            id: true
          }
        }
      }
    ]
  });

  if (!resp.deleteAddress || resp.deleteAddress.__typename === 'GraphQLError') {
    throw new Error(resp.deleteAddress?.message ?? 'Unexpected error');
  }
};

export default deleteAddressAction;
