import { DocumentTemplate } from '@thedealersconcierge/lib/pdf-gen';
import { legalStamps } from '@thedealersconcierge/lib/pdf-gen/templates/legal-stamp';
import { TFunction } from 'i18next';

type Fields = 'name' | 'dateTime' | 'deviceId' | 'ipAddress';
export const vehicleDiscolureTemplate: (
  t: TFunction
) => DocumentTemplate<Fields> = (t: TFunction) => ({
  fileUrl: t('test_drive_disclosure_onboarding', {
    ns: 'files',
    defaultValue: '/docs/test_drive_disclosure.pdf'
  }),
  fields: {
    ...legalStamps('form-test-drive-disclosure', [0, 1])
  }
});
