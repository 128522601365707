import classNames from 'classnames';
import { FC, ReactNode } from 'react';

const ConsumerDashboardHeader: FC<{
  leftElement?: ReactNode;
  centerElement?: ReactNode;
  rightElement?: ReactNode;
  showBorder?: boolean;
}> = ({ leftElement, centerElement, rightElement, showBorder }) => {
  return (
    <div
      className={classNames(
        'grid grid-cols-5 md:grid-cols-3 items-center px-6 pt-5 pb-4 bg-white',
        {
          'border-b border-secondary': showBorder
        }
      )}
    >
      <div className="flex justify-start">{leftElement}</div>

      <div className="flex justify-center col-span-3 md:col-span-1">
        {centerElement}
      </div>

      <div className="flex justify-end">{rightElement}</div>
    </div>
  );
};

export default ConsumerDashboardHeader;
