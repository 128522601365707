import { FC } from "react";
import Button from "../Button";

type ThumbnailProps = {
  file: File;
  onDelete: (fileName: string) => void;
};

const FileInputThumbnail: FC<ThumbnailProps> = ({ file, onDelete }) => {
  const isImage = file.type.startsWith('image/');
  const src = URL.createObjectURL(file);

  return (
    <div className="relative justify-center p-2">
      <div className="absolute inset-0 z-10 flex items-center rounded-md justify-center bg-black bg-opacity-50 opacity-0 hover:opacity-100">
        <Button
          onClick={() => onDelete(file.name)}
          variant="DESTRUCTIVE"
          size="SMALL"
        >
          Delete
        </Button>
      </div>

      <div className='flex flex-col justify-between items-center h-full'>
        {isImage ? (
          <img src={src} alt={file.name} className="h-24 min-w-24 object-cover rounded-md" onLoad={() => URL.revokeObjectURL(src)} />
        ) : (
          <div className="h-20 flex w-full items-center justify-center border rounded-md">
            <p className="text-xs text-center">PDF</p>
          </div>
        )}
        <span className="text-xs text-center mt-4">{file.name}</span>
      </div>
    </div>
  );
};

export default FileInputThumbnail;