import { MeQueryType } from '~/queries/meQuery';
import { TransactionQueryType } from '~/queries/transactionQuery';

export const isBuyer = (
  meData?: MeQueryType,
  transactionData?: TransactionQueryType
): boolean | undefined => {
  if (!transactionData?.transaction?.buyerId) {
    return undefined;
  }
  return transactionData.transaction.buyerId === meData?.me?.user?.id;
};
