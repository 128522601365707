import classNames from 'classnames';
import { FC, ReactNode } from 'react';

const SectionElementRow: FC<{
  title: string;
  subtitle?: string;
  subtitleClassName?: string;
  iconLeft: ReactNode;
  iconLeftColor: string;
  iconRight?: ReactNode;
  onClick?: () => void;
}> = ({
  title,
  subtitle,
  subtitleClassName,
  iconLeft,
  iconLeftColor,
  iconRight,
  onClick
}) => {
  return (
    <div
      className={classNames(
        'flex flex-row py-3 justify-between bg-white md:px-14 items-center',
        { 'cursor-pointer': onClick }
      )}
      onClick={onClick}
    >
      <div className="flex flex-row space-x-6 items-center">
        <div
          className="flex w-12 aspect-square rounded-lg items-center justify-center text-white"
          style={{ backgroundColor: iconLeftColor }}
        >
          {iconLeft}
        </div>

        <div>
          <p>{title}</p>

          {subtitle && (
            <p className={classNames('text-tertiary', subtitleClassName)}>
              {subtitle}
            </p>
          )}
        </div>
      </div>

      {iconRight && <div>{iconRight}</div>}
    </div>
  );
};

export default SectionElementRow;
